import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal } from "components/Modal/Modal";
import { Capture1, Headline4, Overline2, Subtitle2 } from "components/Text/Text";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useState } from "react";
import { Star } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import type { TicketHistoryType } from "../constants";

const USER_TICKET_HISTORY = "users" as const satisfies TicketHistoryType;
const ADDRESS_TICKET_HISTORY = "addresses" as const satisfies TicketHistoryType;

interface TicketHistoryModalProps {
  ticketId: string;
  isOpen: boolean;
  onClose: () => void;
}

const HISTORY_ITEM_AMOUNT = 5;
export function TicketHistoryModal({ ticketId, isOpen, onClose }: TicketHistoryModalProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const [ticketHistoryType, setTicketHistoryType] = useState<TicketHistoryType>(USER_TICKET_HISTORY);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const api = useApi();

  const {
    data: ticketHistoryData,
    hasNextPage: hasMoreHistory,
    isLoading: isLoadingHistory,
    isFetchingNextPage: isLoadingMoreHistory,
    fetchNextPage: loadMoreHistory,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.TICKETS_HISTORY(projectId, ticketId, ticketHistoryType),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getTicketsHistoryDetailsV1(ticketId, ticketHistoryType, {
          Limit: HISTORY_ITEM_AMOUNT,
          Offset: pageParam * HISTORY_ITEM_AMOUNT,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.history.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: ticketId !== undefined && isOpen,
  });

  const ticketHistory = {
    userTotal: ticketHistoryData?.pages[0].userTotal || 0,
    addressTotal: ticketHistoryData?.pages[0].addressTotal || 0,
    history: ticketHistoryData?.pages.flatMap((x) => x.history.items) || [],
  };

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreHistory && hasMoreHistory) {
        void loadMoreHistory();
      }
    }, [isLoadingMoreHistory, hasMoreHistory, loadMoreHistory]),
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnEsc>
      <div className="flex w-96 flex-col gap-4 py-4">
        <Headline4 className="pl-4 uppercase">{t("page.tickets.details.service-history.modal.title")}</Headline4>
        <div className="flex max-h-[600px] flex-col gap-0 overflow-y-auto">
          <div className="flex w-full pl-4">
            <button
              className={twJoin(
                "cursor-pointer rounded-tl-lg px-4 py-1.5",
                ticketHistoryType === USER_TICKET_HISTORY ? "bg-aop-basic-blue text-white" : "bg-grey-lightest",
              )}
              type="button"
              onClick={() => setTicketHistoryType(USER_TICKET_HISTORY)}
            >
              <Capture1>
                {t("page.tickets.details.service-history.modal.tab.resident", { count: ticketHistory.userTotal })}
              </Capture1>
            </button>

            <button
              className={twJoin(
                "cursor-pointer rounded-tr-lg px-4 py-1.5",
                ticketHistoryType === ADDRESS_TICKET_HISTORY ? "bg-aop-basic-blue text-white" : "bg-grey-lightest",
              )}
              type="button"
              onClick={() => setTicketHistoryType(ADDRESS_TICKET_HISTORY)}
            >
              <Capture1>
                {t("page.tickets.details.service-history.modal.tab.address", {
                  count: ticketHistory.addressTotal,
                })}
              </Capture1>
            </button>
          </div>
          {isLoadingHistory && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
          {ticketHistory.history.map((historyItem) => (
            <button
              className="flex cursor-pointer flex-col gap-2 border-b border-grey-lightest py-4 pl-4 hocus:bg-grey-lightest"
              key={historyItem.id}
              type="button"
              onClick={() => navigate(routes.tickets.details({ slug, id: historyItem.id }))}
            >
              <div className="flex items-center gap-2">
                <span className="rounded-lg bg-blue-lightest px-2 py-1 font-semibold text-black">{historyItem.id}</span>
                <span
                  className="rounded-lg px-2 py-1 font-semibold uppercase text-white"
                  style={{ backgroundColor: historyItem.status.color }}
                >
                  {historyItem.status.name}
                </span>
                {historyItem.rating && (
                  <span className="flex items-center gap-0.5 font-semibold">
                    <Star size={16} />
                    {historyItem.rating}
                  </span>
                )}
              </div>
              <Subtitle2 className="break-words">{historyItem.title}</Subtitle2>
              <Overline2>
                {t("page.tickets.table.content.title", {
                  when: formatDate(i18n, "datetimeShort", historyItem.lastActivityAt),
                  who: historyItem.lastActivityBy?.fullName || historyItem.user.fullName,
                })}
              </Overline2>
            </button>
          ))}
          {hasMoreHistory && (
            <div className="h-16 p-4" ref={ref}>
              {isLoadingMoreHistory && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
