import type { AssetBookingDto, BookableAssetDeletedResult, BookableAssetDetailDto, CreatedEntityDto } from "api/types";
import SuccessIcon from "assets/images/party.png";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { Carousel } from "components/Carousel/Carousel";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { addDays, addMinutes, endOfDay, format, isEqual, min, parse, startOfDay, subMinutes } from "date-fns";
import { dayOfWeekIndex } from "helpers/date";
import { createRequiredStringRule } from "helpers/rules";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { Specification } from "modules/bookings/components/Specification";
import { daysOptions, type TimeSlot } from "modules/bookings/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Check, Clock as ClockIcon, FileText as FileIcon } from "react-feather";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  assetDetails: BookableAssetDetailDto;
  minDate: Date;
  maxDate: Date;
  defaultValues: DefaultFormValues;
  onDateChange: (date: Date | null) => void;
  bookings: AssetBookingDto[];
  isLoadingBookings: boolean;
  onSubmit: (data: FormValues) => Promise<CreatedEntityDto>;
  isSubmitting: boolean;
  futureBookings: number;
  onDelete: (id: string) => Promise<BookableAssetDeletedResult>;
}

export interface FormValues {
  date: Date;
  bookAllDay: boolean;
  startTime: Date | null;
  endTime: Date | null;
  reason?: string;
  isRegulationAccepted: boolean;
}

export type DefaultFormValues = Omit<FormValues, "bookAllDay">;

export function Layout({
  assetDetails,
  minDate,
  maxDate,
  defaultValues,
  onDateChange,
  bookings,
  isLoadingBookings,
  onSubmit,
  isSubmitting,
  futureBookings,
  onDelete,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();
  const navigate = useNavigate();

  const [hoveredTimeslot, setHoveredTimeslot] = useState<Date | null>(null);
  const [isSuccessModalOpen, successModalHandler] = useBool(false);

  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-asset-modal");

  const form = useForm<FormValues>({ defaultValues });

  const date = useWatch({ control: form.control, name: "date" });
  const startTime = useWatch({ control: form.control, name: "startTime" });
  const endTime = useWatch({ control: form.control, name: "endTime" });
  const bookAllDay = useWatch({ control: form.control, name: "bookAllDay" });

  useEffect(() => {
    form.register("startTime", {
      validate: {
        required: () =>
          !bookAllDay && !startTime ? t("page.bookings.book-asset.form.booking-timeslot.error") : undefined,
      },
    });
  }, [form, t, startTime, bookAllDay]);

  const isDisabledDay = useCallback(
    (current: Date) => {
      const disabledDays = assetDetails.bookableDays
        .filter((day) => !day.enabled)
        .map((day) => daysOptions.indexOf(day.day));

      return disabledDays.includes(dayOfWeekIndex(current));
    },
    [assetDetails.bookableDays],
  );

  const [availableTimeslots, bookedTimeslots, dayAvailability] = useMemo(() => {
    const weekDay = daysOptions[dayOfWeekIndex(date)];

    const bookableDay = assetDetails.bookableDays.find((day) => day.day === weekDay);

    if (!bookableDay?.enabled) {
      return [[], []];
    }

    const publishAt = assetDetails.publishAt ? new Date(assetDetails.publishAt) : undefined;
    const availableFrom = assetDetails.availableFrom ? new Date(assetDetails.availableFrom) : undefined;
    const unpublishAt = assetDetails.unpublishAt ? new Date(assetDetails.unpublishAt) : undefined;

    const opensAt =
      publishAt && availableFrom
        ? publishAt > availableFrom
          ? publishAt
          : availableFrom
        : publishAt || availableFrom || new Date();

    if (opensAt && opensAt > endOfDay(date)) {
      return [[], []];
    }

    if (unpublishAt && unpublishAt < startOfDay(date)) {
      return [[], []];
    }

    const availableTimes = {
      start: bookableDay.allDay ? "00:00:00" : bookableDay.startTime!,
      end: bookableDay.allDay ? "00:00:00" : bookableDay.endTime!,
    };

    const openTime = parse(availableTimes.start, "HH:mm:ss", date);
    let closeTime = parse(availableTimes.end, "HH:mm:ss", availableTimes.end === "00:00:00" ? addDays(date, 1) : date);

    if (unpublishAt && unpublishAt < closeTime) {
      closeTime = unpublishAt;
    }

    const allTimeslots = [];
    for (let i = openTime; i < closeTime; i = getTimeslotEndTime(i, closeTime, assetDetails.timeslot)) {
      if (opensAt > i) {
        continue;
      }

      if (getTimeslotEndTime(i, closeTime, assetDetails.timeslot) <= closeTime) {
        allTimeslots.push(i);
      }
    }

    const availableTimeslots = allTimeslots.filter((timeslot) => timeslot > new Date());

    const existingBookingsOnDay = bookings.filter((booking) => booking.date === format(date, "yyyy-MM-dd"));
    const bookedTimeslots: Date[] = [];
    for (let i = 0; i < existingBookingsOnDay.length; i++) {
      const start = parse(existingBookingsOnDay[i].startTime, "HH:mm:ss", date);
      const end = parse(
        existingBookingsOnDay[i].endTime,
        "HH:mm:ss",
        existingBookingsOnDay[i].endTime === "00:00:00" ? addDays(date, 1) : date,
      );

      for (let j = start; j < end; j = getTimeslotEndTime(j, closeTime, assetDetails.timeslot)) {
        // Do not consider own booking being edited
        if (
          (!defaultValues.startTime && !defaultValues.endTime) ||
          (defaultValues.startTime &&
            defaultValues.endTime &&
            (j < defaultValues.startTime || j > defaultValues.endTime))
        ) {
          bookedTimeslots.push(j);
        }
      }
    }

    return [availableTimeslots, bookedTimeslots, { openTime, closeTime }];
  }, [date, assetDetails, bookings, defaultValues]);

  const actions: ContextMenuAction[] = useMemo(() => {
    const actions = [];
    if (assetDetails.canEdit) {
      actions.push({
        text: t("common.action.edit"),
        callback: () => navigate(routes.bookings.editAsset({ slug, id: assetDetails.id })),
      });
    }
    if (assetDetails.canDelete) {
      actions.push({
        text: t("common.action.delete"),
        callback: () => openDeleteModal(assetDetails.id),
      });
    }

    return actions;
  }, [slug, t, navigate, assetDetails, openDeleteModal]);

  const canBookAllDay = useMemo(() => {
    const weekday = daysOptions[dayOfWeekIndex(date)];

    const day = assetDetails.bookableDays.find((day) => day.day === weekday)!;

    return (
      assetDetails.timeslot !== "allDay" &&
      assetDetails.canBookMultipleSlots &&
      availableTimeslots.length > 0 &&
      bookedTimeslots.length === 0 &&
      day.enabled
    );
  }, [date, assetDetails, availableTimeslots.length, bookedTimeslots.length]);

  function isTimeslotBooked(timeslot: Date): boolean {
    return bookedTimeslots.find((bookedTimeslot) => bookedTimeslot.getTime() === timeslot.getTime()) !== undefined;
  }

  function isTimeslotButtonDisabled(timeslot: Date): boolean {
    // We disable the timeslot if the option to book all day is checked and a timeslot is before the start time or after an existing booking
    return (
      bookAllDay ||
      (startTime
        ? timeslot < startTime ||
          (timeslot > startTime && !assetDetails.canBookMultipleSlots) ||
          (startTime < min(bookedTimeslots.filter((bookedTimeslot) => bookedTimeslot > startTime)) &&
            timeslot >= min(bookedTimeslots.filter((bookedTimeslot) => bookedTimeslot > startTime)))
        : false)
    );
  }

  function isTimeslotButtonSelected(timeslot: Date): boolean {
    // We show every timeslot as selected in between start and end time
    return !!(
      startTime &&
      startTime <= timeslot &&
      endTime &&
      endTime >= getTimeslotEndTime(timeslot, dayAvailability!.closeTime, assetDetails.timeslot)
    );
  }

  function isTimeslotButtonFilled(timeslot: Date): boolean {
    // We show the timeslot as filled if we selected a start time and if the we are hovering over it and the end time is not selected
    return !!(
      startTime &&
      startTime <= timeslot &&
      ((endTime && endTime >= getTimeslotEndTime(timeslot, dayAvailability!.closeTime, assetDetails.timeslot)) ||
        (hoveredTimeslot && !endTime && timeslot <= hoveredTimeslot))
    );
  }

  function handleDateChange(date: DatePickerValue) {
    onDateChange(date === "" ? null : date);
    if (date !== "") {
      form.setValue("date", date);
      form.setValue("startTime", null);
      form.setValue("endTime", null);
      form.setValue("bookAllDay", false);
    }
  }

  function handleTimeslotChange(timeslot: Date) {
    if (bookedTimeslots.find((bookedTimeslot) => bookedTimeslot.getTime() === timeslot.getTime())) {
      return;
    }

    if (startTime?.getTime() === timeslot.getTime() && endTime) {
      form.setValue("startTime", null);
      form.setValue("endTime", null);
    } else if (!startTime) {
      form.setValue("startTime", timeslot);
      if (assetDetails.timeslot === "allDay") {
        form.setValue("endTime", getTimeslotEndTime(timeslot, dayAvailability!.closeTime, assetDetails.timeslot));
      } else {
        if (!assetDetails.canBookMultipleSlots) {
          form.setValue("endTime", getTimeslotEndTime(timeslot, dayAvailability!.closeTime, assetDetails.timeslot));
        } else {
          form.setValue("endTime", null);
        }
      }
    } else if (startTime && !endTime) {
      form.setValue("endTime", getTimeslotEndTime(timeslot, dayAvailability!.closeTime, assetDetails.timeslot));
    } else if (startTime && endTime) {
      if (
        isEqual(
          timeslot,
          assetDetails.timeslot === "allDay"
            ? startTime
            : subMinutes(endTime, getTimeslotSizeInMinutes(assetDetails.timeslot)),
        )
      ) {
        form.setValue("endTime", timeslot);
      } else {
        form.setValue("endTime", getTimeslotEndTime(timeslot, dayAvailability!.closeTime, assetDetails.timeslot));
      }
    }
  }

  function handleBookAllDay(checked: boolean) {
    form.setValue("bookAllDay", checked);
    if (checked) {
      form.setValue("startTime", availableTimeslots[0]);
      form.setValue(
        "endTime",
        getTimeslotEndTime(
          availableTimeslots[availableTimeslots.length - 1],
          dayAvailability!.closeTime,
          assetDetails.timeslot,
        ),
      );
    } else {
      form.setValue("startTime", null);
      form.setValue("endTime", null);
    }
  }

  async function handleSubmit() {
    if (!endTime) {
      form.setValue("endTime", getTimeslotEndTime(startTime!, dayAvailability!.closeTime, assetDetails.timeslot));
    }

    await onSubmit(form.getValues());

    successModalHandler.setTrue();
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={assetDetails.name}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.bookings.list-assets.title"),
              to: routes.bookings.list({ slug }),
            },
            {
              name: assetDetails.name,
            },
          ]}
        />
      }
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="h-fit rounded-lg shadow-sm">
          <div className="h-52 w-full md:h-72 lg:h-96">
            <Carousel images={assetDetails.images} rounded="top" />
          </div>
          <div className="flex flex-col gap-4 rounded-b-lg bg-white p-4">
            <div className="flex justify-between">
              <Headline4>{assetDetails.name}</Headline4>
              <ContextMenu actions={actions} />
            </div>
            <div className="flex items-center gap-4">
              <Specification specification="sizeSpecification" value={assetDetails.sizeSpecification} />
              <Specification specification="pricePerHourSpecification" value={assetDetails.pricePerHourSpecification} />
              <Specification specification="capacitySpecification" value={assetDetails.capacitySpecification} />
              <Specification specification="locationSpecification" value={assetDetails.locationSpecification} />
            </div>
            <Subtitle2 className="font-normal">
              <LinkFormatter>{assetDetails.description}</LinkFormatter>
            </Subtitle2>
          </div>
        </div>
        <div className="flex h-fit flex-col gap-4 rounded-lg bg-white p-4 shadow-sm">
          <Headline4>{t("page.bookings.book-asset.section.book-asset.title")}</Headline4>
          {maxDate < minDate ? (
            <p>{t("page.bookings.book-asset.section.book-asset.not-available")}</p>
          ) : (
            <Form formMethods={form} onSubmit={handleSubmit}>
              <FormContent maxWidth="4xl">
                <FormField label={t("page.bookings.book-asset.form.booking-date.label")}>
                  <FormDateAndTimePicker<FormValues, "date">
                    data-testid="date-picker-input"
                    name="date"
                    min={minDate}
                    max={maxDate}
                    disabledDate={isDisabledDay}
                    onChange={handleDateChange}
                    rules={{
                      validate: {
                        laterThanMin: (date) => {
                          if (!date) {
                            return undefined;
                          }

                          return date < minDate
                            ? t("page.bookings.book-asset.form.booking-date.error.must-be-after-date", {
                                date: formatDate(i18n, "date", minDate),
                              })
                            : undefined;
                        },
                        soonerThanMax: (date) => {
                          if (!date) {
                            return undefined;
                          }

                          if (!maxDate) {
                            return;
                          }

                          return date > maxDate
                            ? t("page.bookings.book-asset.form.booking-date.error.must-be-before-date", {
                                date: formatDate(i18n, "date", maxDate),
                              })
                            : undefined;
                        },
                      },
                    }}
                  />
                </FormField>
                <FormErrorWrapper name="startTime" encircle>
                  <div className="flex flex-col gap-2">
                    <FormField
                      label={
                        !assetDetails.canBookMultipleSlots
                          ? t("page.bookings.book-asset.form.booking-timeslot.restricted")
                          : startTime === null
                            ? t("page.bookings.book-asset.form.booking-timeslot.first")
                            : t("page.bookings.book-asset.form.booking-timeslot.second")
                      }
                    >
                      <div className="flex flex-wrap gap-2">
                        {isLoadingBookings ? (
                          <div className="flex w-full justify-center">
                            <LoadingIcon className="size-6" />
                          </div>
                        ) : availableTimeslots.length > 0 ? (
                          availableTimeslots.map((timeslot, idx) => (
                            <button
                              data-testid="timeslot-btn"
                              key={`timeslot_${idx}`}
                              type="button"
                              className={twResolve(
                                "rounded-full  bg-green-lightest px-2 py-1 text-green-darker hover:bg-green-darker hover:text-white disabled:bg-grey-lightest disabled:text-grey",
                                isTimeslotButtonFilled(timeslot) && "bg-green-darker text-white hover:bg-green-darkest",
                                isTimeslotBooked(timeslot) &&
                                  "cursor-default bg-red-lightest text-red-dark hover:bg-red-lightest hover:text-red-dark",
                              )}
                              onClick={() => handleTimeslotChange(timeslot)}
                              onMouseEnter={() => setHoveredTimeslot(timeslot)}
                              disabled={isTimeslotButtonDisabled(timeslot)}
                            >
                              <Capture2 className="flex items-center gap-1">
                                {isTimeslotButtonSelected(timeslot) ? (
                                  <Check className="size-4" />
                                ) : (
                                  <ClockIcon className="size-4" />
                                )}
                                {`${assetDetails.timeslot === "allDay" ? `${t("page.bookings.book-asset.form.booking-timeslot.full-day")} ` : ""}${format(timeslot, "HH:mm")} - ${format(getTimeslotEndTime(timeslot, dayAvailability!.closeTime, assetDetails.timeslot), "HH:mm")}`}
                              </Capture2>
                            </button>
                          ))
                        ) : (
                          <Subtitle2 className="font-normal">
                            {t("page.bookings.book-asset.form.booking-timeslot.no-slots.available")}
                          </Subtitle2>
                        )}
                      </div>
                    </FormField>
                    {canBookAllDay ? (
                      <FormCheckbox
                        name="bookAllDay"
                        label={t("page.bookings.book-asset.form.booking-timeslot.all-day")}
                        onChange={(event) => handleBookAllDay(event.target.checked)}
                      />
                    ) : null}
                  </div>
                </FormErrorWrapper>
                {assetDetails.requireBookingReason ? (
                  <FormField label={t("page.bookings.book-asset.form.booking-reason.label")} required>
                    <FormInput<FormValues, "reason">
                      name="reason"
                      placeholder={t("page.bookings.book-asset.form.booking-reason.placeholder")}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "page.bookings.book-asset.form.booking-reason.name"),
                        },
                      }}
                    />
                  </FormField>
                ) : null}
                {assetDetails.regulationDocument ? (
                  <FormErrorWrapper name="isRegulationAccepted" encircle>
                    <div className="flex flex-col gap-1">
                      <FormCheckbox
                        name="isRegulationAccepted"
                        label={t("page.bookings.book-asset.form.booking-regulations.label")}
                        alignTop
                        rules={{
                          validate: {
                            required: (value) => {
                              return value ? undefined : t("page.bookings.book-asset.form.booking-regulations.error");
                            },
                          },
                        }}
                      />
                      <Button
                        styling="tertiary"
                        onClick={() => window.open(assetDetails.regulationDocument?.url, "_blank")}
                        icon={<FileIcon className="size-5" />}
                        className="max-w-full"
                      >
                        <span className="truncate">{assetDetails.regulationDocument?.fileName}</span>
                      </Button>
                    </div>
                  </FormErrorWrapper>
                ) : null}
                <div className="flex w-full justify-end">
                  <Button type="submit" isLoading={isSubmitting}>
                    {t("page.bookings.book-asset.book")}
                  </Button>
                </div>
              </FormContent>
            </Form>
          )}
        </div>
      </div>
      <Modal isOpen={isSuccessModalOpen} isActionRequired shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
        <div className="flex flex-col items-center justify-between gap-8 p-4">
          <Headline4>{t("page.bookings.book-asset.success-modal.title")}</Headline4>
          <img src={SuccessIcon} alt="success" />
          <div className="flex w-full flex-col items-center gap-2">
            <span className="w-full">
              <Button
                onClick={() =>
                  hasPermission((x) => x.assets.canViewSchedule)
                    ? navigate(routes.reservations.list({ slug }))
                    : navigate({ pathname: routes.calendar.list({ slug }), search: "tab=reservations" })
                }
                className="w-full"
              >
                {t("page.bookings.book-asset.success-modal.see-details")}
              </Button>
            </span>
            <Button styling="tertiary" onClick={() => navigate(routes.bookings.list({ slug }))}>
              {t("common.action.close")}
            </Button>
          </div>
        </div>
      </Modal>
      <DeleteModal
        title={t("page.bookings.delete-asset.modal.title")}
        description={
          futureBookings === 0
            ? t("page.bookings.delete-asset.modal.description.no-bookings")
            : t("page.bookings.delete-asset.modal.description", { count: futureBookings })
        }
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}

function getTimeslotSizeInMinutes(timeslot: TimeSlot): number {
  switch (timeslot) {
    case "fifteenMinutes":
      return 15;
    case "thirtyMinutes":
      return 30;
    case "oneHour":
      return 60;
    case "twoHours":
      return 60 * 2;
    case "threeHours":
      return 60 * 3;
    case "fourHours":
      return 60 * 4;
    case "allDay":
      return 60 * 24;
  }
}

function getTimeslotEndTime(startTime: Date, dayEndTime: Date, timeslot: TimeSlot): Date {
  switch (timeslot) {
    case "fifteenMinutes":
      return addMinutes(startTime, 15);
    case "thirtyMinutes":
      return addMinutes(startTime, 30);
    case "oneHour":
      return addMinutes(startTime, 60);
    case "twoHours":
      return addMinutes(startTime, 60 * 2);
    case "threeHours":
      return addMinutes(startTime, 60 * 3);
    case "fourHours":
      return addMinutes(startTime, 60 * 4);
    case "allDay":
      return dayEndTime;
  }
}
