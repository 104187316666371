import type { MessageV2Dto } from "api/types";
import { Button } from "components/Button/Button";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSlug } from "hooks/useSlug";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { CommunityPost } from "../List/components/CommunityPost";

interface Props {
  message: MessageV2Dto;
}

export function PostDetails({ message }: Props): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  return (
    <DocumentPaper theme="no-header-minimal">
      <div className="flex flex-col gap-9">
        <Button type="link" styling="tertiary" className="text-aop-basic-blue" href={routes.messageFeed.list({ slug })}>
          <ChevronLeft size={16} /> {t("page.message-details.go-back")}
        </Button>
        <CommunityPost message={message} isExpanded />
      </div>
    </DocumentPaper>
  );
}
