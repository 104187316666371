import type { CommunityGroupV2Dto, SelfProjectDto } from "api/types";
import { Button } from "components/Button/Button";
import { Paper } from "components/Paper/Paper";
import { Capture1, Headline4, Subtitle2 } from "components/Text/Text";
import { useSlug } from "hooks/useSlug";
import { NextButton } from "modules/onboarding-flow/components/NextButton";
import { OnboardingGroup } from "modules/onboarding-flow/components/OnboardingGroup";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  helpCategories: CommunityGroupV2Dto[];
  project: SelfProjectDto;
  onFollow: (id: string) => void;
  onUnfollow: (id: string) => void;
  onMarkOnboarded: () => void;
  isMarkingAsOnboarded: boolean;
}

export function Layout({
  helpCategories,
  project,
  onFollow,
  onUnfollow,
  onMarkOnboarded,
  isMarkingAsOnboarded,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col">
      <div className="flex-auto md:flex md:h-full md:flex-row">
        <main className="z-10 min-h-full w-full overflow-auto">
          <Paper theme="no-header-minimal">
            <div className="flex size-full items-center justify-center">
              <div className="flex min-h-[700px] w-[320px] flex-col justify-between gap-3 overflow-y-auto rounded-lg bg-white shadow-md">
                <div className="flex flex-col gap-4 p-4">
                  <Button
                    type="link"
                    styling="tertiary"
                    className="p-0 text-aop-basic-blue underline"
                    href={routes.onboardingFlow.groups({ slug })}
                  >
                    <ChevronLeft size={16} />
                    {t("common.action.back")}
                  </Button>
                  <Headline4>{t("page.onboarding.help-categories.title")}</Headline4>
                  <Subtitle2 className="font-normal">{t("page.onboarding.help-categories.description")}</Subtitle2>
                  <Capture1 className="text-grey-dark">{t("page.onboarding.help-categories.subtext")}</Capture1>
                </div>
                {helpCategories.map((helpCategory) => (
                  <OnboardingGroup
                    key={helpCategory.id}
                    group={helpCategory}
                    projectDefaultImage={project.backgroundImage}
                    onFollow={onFollow}
                    onUnfollow={onUnfollow}
                  />
                ))}
                <div className="flex justify-end justify-self-end">
                  <NextButton isLoading={isMarkingAsOnboarded} onClick={onMarkOnboarded}>
                    {t("common.action.next")}
                  </NextButton>
                </div>
              </div>
            </div>
          </Paper>
        </main>
      </div>
    </div>
  );
}
