import type { ConstraintListItemDto } from "api/types";
import iconChevronRight from "assets/icons/chevron-right.svg";
import iconPlus from "assets/icons/plus.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import { Icon } from "components/Icon/Icon";
import type React from "react";
import { useTranslation } from "react-i18next";

import { getIconByAudienceType, orderAudienceTypes } from "./CreateOrEditAssetsAudienceDrawer";

type AudienceType = ConstraintListItemDto["type"];

interface CreateOrEditAssetsAudiencePreviewButtonProps {
  audience: Record<AudienceType, ConstraintListItemDto[]>;
}

export function CreateOrEditAssetsAudiencePreviewButton({
  audience,
  ...otherProps
}: CreateOrEditAssetsAudiencePreviewButtonProps): React.ReactNode {
  const { t } = useTranslation();

  const filteredAudience = Object.fromEntries(
    Object.entries(audience).filter(([, items]) => items.length > 0),
  ) as Record<ConstraintListItemDto["type"], ConstraintListItemDto[]>;
  const availableAudienceTypes = Object.keys(filteredAudience) as AudienceType[];

  return (
    <button
      type="button"
      className="flex w-full cursor-pointer items-center justify-between gap-4 rounded-lg p-2 ring-1 ring-grey-lighter hover:ring-grey-darker focus-visible:outline-none focus-visible:ring-grey-darkest"
      {...otherProps}
    >
      <div className="flex items-center gap-1">
        <Icon name={iconUsersCheck} size={16} />
        <span>{t("common.entity.audience")}:</span>
        {availableAudienceTypes.length === 0 && (
          <CreateOrEditAssetsAudiencePreviewItem label={t("component.audience-drawer.default-audience.label")} />
        )}
        {availableAudienceTypes.length > 0 &&
          orderAudienceTypes(availableAudienceTypes).map((type, index) => {
            const items = filteredAudience[type];
            const label = items.map(({ key }) => key).join(", ");
            const icon = getIconByAudienceType(type);

            // Only show the first available audience type
            // and omit the rest of the audience types
            if (index === 0) {
              return <CreateOrEditAssetsAudiencePreviewItem key={type} {...{ icon, label }} />;
            } else if (index === 1) {
              const amountAudienceOmitted = Object.keys(filteredAudience).length - 1;

              return (
                <CreateOrEditAssetsAudiencePreviewItem
                  key={type}
                  icon={iconPlus}
                  label={t("component.audience-drawer.ommited-audience.label", {
                    amountOmitted: amountAudienceOmitted,
                  })}
                />
              );
            }
          })}
      </div>
      <Icon name={iconChevronRight} size={16} />
    </button>
  );
}

interface CreateOrEditAssetsAudiencePreviewItemProps {
  icon?: string;
  label: string;
}

export function CreateOrEditAssetsAudiencePreviewItem({
  icon,
  label,
}: CreateOrEditAssetsAudiencePreviewItemProps): React.ReactNode {
  return (
    <span className="flex items-center gap-1 rounded-[4px] bg-grey-lightest px-1 text-sm text-black">
      {icon && <Icon name={icon} size={16} />}
      <span>{label}</span>
    </span>
  );
}
