import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AdminTicketCommentDto, UserDto } from "api/types";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { motion } from "framer-motion";
import { stripTime } from "helpers/date";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { groupBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { Lock } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface TicketActivityWrapperProps {
  postedAt: string;
  author: UserDto;
  withoutBorder?: boolean;
}

export function TicketActivityWrapper({
  postedAt,
  author,
  withoutBorder,
  children,
}: React.PropsWithChildren<TicketActivityWrapperProps>): React.ReactNode {
  return (
    <article className="my-2 flex rounded-3px p-2 pb-0">
      <div className="mr-2 size-8">
        <UserAvatarLink user={author} hideDeletedIcon />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className="flex flex-wrap items-center gap-1">
          <UserNameLink user={author}>
            <Subtitle2 className="truncate text-black">{author.fullName}</Subtitle2>
          </UserNameLink>
          {!!author.deletedAt && <UserDeletedTag />}
          <Capture2 className="ml-1 text-grey">
            <FormattedDate date={postedAt} format="time" />
          </Capture2>
        </div>
        <p
          className={twJoin(
            "my-1 whitespace-pre-line py-2 italic",
            withoutBorder ? undefined : "rounded-3px border border-grey-lighter px-2",
          )}
        >
          {children}
        </p>
      </div>
    </article>
  );
}

interface TicketCommentProps {
  ticketId: string;
  comment: AdminTicketCommentDto;
  onEdit?: (comment: AdminTicketCommentDto) => void;
}

// This is a terrible abstraction, should be fixed some day
export function TicketComment({ ticketId, comment, onEdit }: TicketCommentProps): React.ReactNode {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();

  const isNote = comment.internal;
  const api = useApi();
  const showFlashToast = useFlashToast();

  const [hideTranslation, hideTranslationHandler] = useBool(true);
  const translation = useQuery({
    queryKey: QUERY_KEYS.TICKET_COMMENT_TRANSLATION(projectId, ticketId, comment.id, sessionUser.language.id),
    queryFn: () => api.getTicketsCommentsTranslationsDetailsV1(ticketId, comment.id, sessionUser.language.id),
    retry: false,
    enabled: false,
  });

  const hasTranslation = translation.data && !hideTranslation;
  const translateButton =
    comment.content &&
    comment.languageIsoCode !== sessionUser.language.id &&
    comment.author.id !== sessionUser.id &&
    !isNote &&
    !comment.deletedAt &&
    !comment.isAutoReply ? (
      <Button
        styling="ghostPrimary"
        isLoading={translation.isLoading}
        onClick={async () => {
          hideTranslationHandler.toggle();

          if (!translation.data) {
            try {
              await translation.refetch();
            } catch (error) {
              showFlashToast({ type: "error", title: t("page.tickets.details.activities.comment.translate.failed") });
            }
          }
        }}
      >
        {hasTranslation
          ? t("page.tickets.details.activities.comment.translate.original")
          : t("page.tickets.details.activities.comment.translate")}
      </Button>
    ) : null;

  const content =
    comment.deletedAt && !comment.content
      ? isNote
        ? t("page.tickets.details.activities.note-deleted")
        : t("page.tickets.details.activities.comment-deleted")
      : hasTranslation
        ? translation.data.data.content
        : comment.content;

  return (
    <motion.article
      className={twJoin(
        "my-2 flex rounded-3px p-2 pb-0",
        comment.deletedAt && "text-grey-light",
        isNote ? "mr-2 bg-yellow-lightest pb-2" : "first:pt-0",
      )}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0, marginBottom: "0px" }}
      data-testid="comment-root"
    >
      <div className={twJoin("mr-2 size-8", comment.deletedAt ? "opacity-40" : undefined)}>
        <UserAvatarLink user={comment.author} hideDeletedIcon />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className={twJoin("flex items-start", comment.deletedAt ? "text-grey-light" : undefined)}>
          <div className="flex flex-wrap items-center gap-1">
            <UserNameLink user={comment.author}>
              <Subtitle2 className={twJoin("truncate", comment.deletedAt ? "text-grey-light" : "text-black")}>
                {comment.author.fullName}
              </Subtitle2>
            </UserNameLink>

            {!!comment.author.deletedAt && <UserDeletedTag />}

            <Capture2 className="ml-1 text-grey">
              <FormattedDate date={comment.postedAt} format="time" />
            </Capture2>

            {comment.updatedAt ? (
              <Tooltip tooltip={<FormattedDate date={comment.updatedAt} format="datetime" />}>
                <Capture2
                  className={
                    "relative ml-1 cursor-default italic text-grey after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey after:content-[''] hover:after:block"
                  }
                >
                  ({t("page.tickets.details.activities.comment-edited")})
                </Capture2>
              </Tooltip>
            ) : null}
          </div>

          {isNote ? (
            <span className="ml-auto mr-1 flex h-6 items-center text-sm text-yellow-darker md:mr-3">
              <Lock className="mr-1 block" size={14} />
              <Capture2 className="hidden sm:block">{t("page.tickets.details.activities.note")}</Capture2>
            </span>
          ) : null}
        </div>

        {content && (
          <p
            className={twJoin(
              "max-w-prose whitespace-pre-line",
              comment.deletedAt && comment.content ? "line-through" : undefined,
            )}
            data-testid="comment-content"
          >
            <LinkFormatter>{content}</LinkFormatter>
            {translateButton}
          </p>
        )}

        {comment.images.length > 0 ? (
          <div className="max-w-full pt-2">
            <Gallery images={comment.images} isDeleted={!!comment.deletedAt} />
          </div>
        ) : null}
        {isNote && comment.author.id === sessionUser.id && onEdit ? (
          <div className="max-w-full pt-2">
            <Button styling="ghostSecondary" size="sm" onClick={() => onEdit(comment)}>
              {t("common.action.edit")}
            </Button>
          </div>
        ) : null}
      </div>
    </motion.article>
  );
}

export function groupActivities<T extends { postedAt: string }>(activities: T[]): [string, T[]][] {
  const groupedByDay = Object.entries(groupBy(activities, (x) => stripTime(x.postedAt).valueOf()));

  return groupedByDay;
}
