import type { AssetBookingDto } from "api/types";
import { IconButton } from "components/Button/IconButton";
import { DateRange } from "components/DateRange/DateRange";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { addDays, addSeconds, differenceInDays, differenceInMinutes, isSameDay, parse, startOfDay } from "date-fns";
import { dayOfWeekIndex } from "helpers/date";
import { min, sortBy } from "lodash-es";
import { Fragment, useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { daysOptions } from "../constants";
import { getAllowedViewableDateRange } from "../helpers";
import type { LayoutProps } from "../pages/AssetDetail/Layout";
import { ToggleCalendarButton } from "./ToggleCalendarButton";

export function CalendarWeekView({
  assetDetails,
  startOfWeek,
  endOfWeek,
  isLoadingBookings,
  bookings,
  onChangeWeek,
  onChangeView,
  onSelectBooking,
}: {
  assetDetails: LayoutProps["assetDetails"];
  startOfWeek: LayoutProps["startDate"];
  endOfWeek: LayoutProps["endDate"];
  isLoadingBookings: boolean;
  bookings: LayoutProps["bookings"];
  onChangeView: () => void;
  onChangeWeek: (startDate: Date) => void;
  onSelectBooking: (booking: AssetBookingDto) => void;
}): React.ReactNode {
  const { i18n, t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);
  const containerNav = useRef(null);

  useEffect(() => {
    // Scroll past the blocked start hours
    const el = container.current;
    if (el) {
      const minStartHourThisWeek =
        min(
          assetDetails.bookableDays.map((x) =>
            x.startTime ? parse(x.startTime, "HH:mm:ss", new Date()).getHours() : 0,
          ),
        ) || 0;

      const minStartHour = Math.max(minStartHourThisWeek, 8);

      const scroll = (minStartHour / 24) * el.scrollHeight - el.offsetHeight * 0.25;

      el.scrollTop = scroll;
    }
  }, [assetDetails.bookableDays]);

  const todayStartOfDay = startOfDay(new Date());

  const hours = Array(24)
    .fill(0)
    .map((_, i) => i);

  const week = Array(7)
    .fill(0)
    .map((_, i) => {
      const date = addDays(startOfWeek, i);

      const isToday = isSameDay(date, new Date());

      const weekDay = formatDate(i18n, "weekDay", date);

      return { date, dayNumber: date.getDate(), name: weekDay, isToday };
    });

  const publishAt = assetDetails.publishAt ? new Date(assetDetails.publishAt) : undefined;
  const availableFrom = assetDetails.availableFrom ? new Date(assetDetails.availableFrom) : undefined;
  const unpublishAt = assetDetails.unpublishAt ? new Date(assetDetails.unpublishAt) : undefined;

  const { minViewDate, maxViewDate } = getAllowedViewableDateRange(assetDetails);

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="my-2 flex w-full flex-col-reverse items-center justify-center gap-4 md:my-0 md:flex-row md:justify-between">
        <div className="flex items-center gap-2">
          <IconButton
            title={t("page.bookings.asset-detail.week-view.previous")}
            onClick={() => onChangeWeek(addDays(startOfWeek, -7))}
            disabled={startOfWeek < minViewDate}
            size="sm"
          >
            <ChevronLeft size={16} />
          </IconButton>
          <span className="min-w-[200px] text-center">
            <DateRange start={startOfWeek} end={addSeconds(endOfWeek, -1)} format="noTime" />
          </span>
          <IconButton
            title={t("page.bookings.asset-detail.week-view.next")}
            onClick={() => onChangeWeek(addDays(startOfWeek, 7))}
            disabled={endOfWeek >= maxViewDate}
            size="sm"
          >
            <ChevronRight size={16} />
          </IconButton>
        </div>
        <div>
          <ToggleCalendarButton isWeek onToggleView={() => onChangeView()} />
        </div>
      </div>
      <div ref={container} className="relative flex max-h-screen-minus-50 flex-auto flex-col overflow-auto bg-white">
        {isLoadingBookings ? (
          <div className="absolute inset-0">
            <FullSizeLoader />
          </div>
        ) : null}
        <div style={{ width: "165%" }} className="flex max-w-none flex-none flex-col md:max-w-full">
          <div ref={containerNav} className="sticky top-0 z-30 flex-none bg-white pr-8 ring-1 ring-black/5">
            <div className="-mr-px grid grid-cols-7 divide-x divide-grey-lightest/50 border-r border-grey-lightest/50 text-xs leading-6 text-grey-dark sm:text-sm">
              <div className="col-end-1 w-12" />
              {week.map((day) => (
                <div key={day.dayNumber} className="flex items-center justify-center py-3">
                  <span className="flex items-baseline">
                    <span className="capitalize">{day.name.substring(0, 1)}</span>
                    <span className="sr-only lg:not-sr-only">{day.name.substring(1, 3)}</span>
                    <span className="sr-only 2xl:not-sr-only">{day.name.substring(3)}</span>
                    <span
                      className={twJoin(
                        "ml-1 flex size-6 items-center justify-center rounded-full font-semibold sm:ml-1.5 sm:size-8",
                        day.isToday ? "bg-aop-basic-blue text-white" : "text-black",
                      )}
                    >
                      {day.dayNumber}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-12 flex-none bg-white ring-1 ring-grey-lightest/50" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-grey-lightest/50"
                style={{ gridTemplateRows: "repeat(48, minmax(2.5rem, 1fr))" }}
              >
                <div className="row-end-1 h-7"></div>
                {hours.map((hour) => (
                  <Fragment key={hour}>
                    <div>
                      <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-12 pr-2 text-right text-xs leading-5 text-grey">
                        {hour}:00
                      </div>
                    </div>
                    <div />
                  </Fragment>
                ))}
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 grid-rows-1 divide-x divide-grey-lightest/50">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 pr-8 "
                style={{ gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto" }}
              >
                {/* Blocked timeslots */}
                {sortBy(assetDetails.bookableDays, (x) => daysOptions.indexOf(x.day)).map((x, i) => {
                  let minMinutes = !x.enabled
                    ? 1440
                    : x.allDay || !x.startTime
                      ? 0
                      : differenceInMinutes(parse(x.startTime, "HH:mm:ss", new Date()), todayStartOfDay);

                  // Block the time before publishAt/availableFrom
                  if (availableFrom && availableFrom > week[i].date) {
                    if (isSameDay(availableFrom, week[i].date)) {
                      minMinutes = Math.max(differenceInMinutes(availableFrom, startOfDay(availableFrom)), minMinutes);
                    } else {
                      minMinutes = 1440;
                    }
                  } else if (publishAt && publishAt > week[i].date) {
                    if (isSameDay(publishAt, week[i].date)) {
                      minMinutes = Math.max(differenceInMinutes(publishAt, startOfDay(publishAt)), minMinutes);
                    } else {
                      minMinutes = 1440;
                    }
                  }

                  let maxMinutes =
                    x.allDay || !x.endTime
                      ? 1440
                      : differenceInMinutes(parse(x.endTime, "HH:mm:ss", new Date()), todayStartOfDay);

                  // Block the time after unpublishAt
                  if (unpublishAt && startOfDay(unpublishAt) <= week[i].date) {
                    if (isSameDay(unpublishAt, week[i].date)) {
                      maxMinutes = Math.min(differenceInMinutes(unpublishAt, startOfDay(unpublishAt)), maxMinutes);
                    } else {
                      minMinutes = 1440;
                    }
                  }

                  if (minMinutes > maxMinutes) {
                    maxMinutes = 1440;
                  }

                  return (
                    <Fragment key={`blocked-slot-${i}`}>
                      {minMinutes > 0 && (
                        <li
                          className="bg-aop-dark-blue/5"
                          style={{
                            gridArea: `1 / ${i + 1} / span ${Math.round((minMinutes + 5) / 5)}`,
                          }}
                          aria-hidden="true"
                        />
                      )}
                      {maxMinutes < 1440 && (
                        <li
                          className="bg-aop-dark-blue/5"
                          style={{
                            gridArea: `${Math.round((maxMinutes + 5) / 5 + 1)} / ${i + 1} / span ${Math.round((1440 - maxMinutes) / 5)}`,
                          }}
                          aria-hidden="true"
                        />
                      )}
                    </Fragment>
                  );
                })}

                {/* Events */}
                {bookings.map((booking) => {
                  const now = new Date();

                  const date = parse(booking.date, "yyyy-MM-dd", startOfDay(new Date()));
                  const startDate = parse(booking.startTime, "HH:mm:ss", date);
                  let endDate = parse(booking.endTime, "HH:mm:ss", date);
                  if (booking.endTime === "00:00:00") {
                    endDate = addSeconds(addDays(endDate, 1), -1);
                  }

                  const daysSinceStartWeek = differenceInDays(date, startOfWeek);

                  const durationInHours = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60;

                  const isSmall = durationInHours < 1;
                  const isVerySmall = durationInHours <= 0.5;
                  const isMini = durationInHours <= 0.25;
                  const inPast = endDate < now;

                  return (
                    <li
                      key={booking.id}
                      className={twJoin("relative mt-px flex", getSmColStart(startDate))}
                      style={{
                        gridArea: `${Math.round(differenceInMinutes(startDate, startOfDay(startDate)) / 5 + 2)} / ${daysSinceStartWeek + 1} / span ${Math.round(durationInHours * 12)}`,
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => onSelectBooking(booking)}
                        className={twJoin(
                          "group absolute inset-x-1 flex rounded-lg text-xs",
                          inPast
                            ? "bg-aop-dark-blue/80 hover:bg-aop-dark-blue/70"
                            : "bg-aop-dark-blue hover:bg-aop-dark-blue/80",
                          isVerySmall ? "gap-1" : "flex-col",
                          isMini
                            ? "inset-y-0.5 overflow-y-visible px-2 py-0.5 leading-none"
                            : "inset-y-1 overflow-y-auto p-2 leading-tight",
                        )}
                      >
                        <p className="order-1 text-left font-semibold text-white/90">
                          <span className={isSmall ? "line-clamp-1" : undefined}>
                            {booking.author?.fullName || t("page.bookings.asset-detail.author-fallback")}
                          </span>
                        </p>
                        <p className="text-white">
                          <time dateTime={startDate.toISOString()}>
                            {startDate.getHours() + ":" + startDate.getMinutes().toString().padStart(2, "0")}
                          </time>
                        </p>
                      </button>
                    </li>
                  );
                })}

                {/* Indicator at current time, maybe some day */}
                {/*
                <li
                  className="relative col-start-1 col-end-2 border-t-2 border-t-aop-bright-purple "
                  style={{
                    gridArea: `${(now.getHours() * 60 + 5) / 5} / ${now.getDay()} / span 1`,
                  }}
                />
                */}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// No string interpolation because tailwind would not find the classes
function getSmColStart(day: Date) {
  switch (dayOfWeekIndex(day)) {
    case 0:
      return "sm:col-start-1";
    case 1:
      return "sm:col-start-2";
    case 2:
      return "sm:col-start-3";
    case 3:
      return "sm:col-start-4";
    case 4:
      return "sm:col-start-5";
    case 5:
      return "sm:col-start-6";
    case 6:
      return "sm:col-start-7";
    default:
      throw new Error(`Day of week index is incorrect`);
  }
}
