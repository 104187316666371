import { createColumnHelper, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import type { CompanyDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Table } from "components/Table/Table";
import { useBool } from "hooks/useBool";
import { useDebounce } from "hooks/useDebounce";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { useDeferredValue, useMemo, useState } from "react";
import { RefreshCcw as RefreshCcwIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  companies: CompanyDto[];
  onRefreshLocationCode: (companyId: string) => Promise<unknown>;
  onDelete: (companyId: string) => Promise<unknown>;
}

const DEBOUNCE_WAIT = 200;

export function Layout({ companies, onRefreshLocationCode, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<CompanyDto>("companies-delete-modal");
  const [locationCodeModalCompanyId, setLocationCodeModalCompanyId] = useState<string>();
  const [isRefreshingLocationCode, refreshingLocationCodeHandlers] = useBool();
  const [query, setQuery] = useState("");
  const deferredQuery = useDeferredValue(useDebounce(query.toLowerCase().trim(), DEBOUNCE_WAIT));

  const canManage = useResolvedPermission((x) => x.addressManagement.canManageCompanies);

  const columns = useMemo(() => {
    const helper = createColumnHelper<CompanyDto>();

    return [
      helper.accessor("name", {
        header: t("page.companies.list.name"),
        cell: (cell) =>
          canManage ? (
            <Anchor to={routes.companies.edit({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          ),
      }),
      helper.accessor("address", {
        header: t("page.companies.list.address"),
        cell: (cell) => (
          <span>
            {cell.getValue().locatedAt} ({cell.row.original.buildingName})
          </span>
        ),
        enableSorting: false,
      }),
      ...(canManage
        ? [
            helper.accessor("locationCode", {
              header: t("page.companies.list.location-code"),
              cell: (cell) => (
                <div className="flex items-center justify-between gap-2">
                  <span>{cell.getValue()}</span>
                  {canManage ? (
                    <IconButton
                      styling="tertiary"
                      title={t("page.companies.list.refresh-location-code")}
                      onClick={() => setLocationCodeModalCompanyId(cell.row.original.id)}
                    >
                      <RefreshCcwIcon size={16} />
                    </IconButton>
                  ) : null}
                </div>
              ),
              enableSorting: false,
            }),
          ]
        : []),
      helper.accessor("activeUserCount", {
        header: t("page.companies.list.active-user-count"),
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      ...(canManage
        ? [
            helper.accessor("inactiveUserCount", {
              header: t("page.companies.list.inactive-user-count"),
              cell: (cell) => <div>{cell.getValue()}</div>,
            }),
            helper.accessor("id", {
              header: "",
              cell: (cell) => {
                const actions: ContextMenuAction[] = [];

                if (canManage) {
                  actions.push({
                    callback: () => {
                      navigate(routes.companies.edit({ slug, id: cell.getValue() }));
                    },
                    text: t("common.action.edit"),
                  });
                }

                if (canManage) {
                  actions.push({
                    callback: () => openDeleteModal(cell.row.original),
                    text: t("common.action.delete"),
                    dataTestId: "context-menu-delete-btn",
                    status: {
                      disabled: !cell.row.original.canDelete,
                      disabledText: t("page.companies.list.actions.delete.forbidden"),
                    },
                  });
                }

                return (
                  <div className="flex justify-end px-2">
                    <ContextMenu className="text-grey-darker" actions={actions} />
                  </div>
                );
              },
              enableSorting: false,
            }),
          ]
        : []),
    ];
  }, [canManage, slug, navigate, openDeleteModal, t]);

  const searchableCompanies = useMemo(
    () =>
      companies.map((x) => ({
        ...x,
        searchableString: `${x.name}|||${x.address.locatedAt} (${x.buildingName})|||${x.locationCode}`.toLowerCase(),
      })),
    [companies],
  );

  const filteredCompanies = useMemo(
    () => searchableCompanies.filter((x) => x.searchableString.includes(deferredQuery)),
    [searchableCompanies, deferredQuery],
  );

  const tableInstance = useReactTable<CompanyDto>({
    columns,
    data: filteredCompanies,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
    },
    enableSortingRemoval: false,
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.companies.list.title")}
      subTitle={t("page.companies.list.subtitle")}
      actions={
        canManage ? (
          <Button type="link" href={routes.companies.new({ slug })} data-testid="companies-create-link">
            {t("page.companies.list.actions.create")}
          </Button>
        ) : null
      }
      header={
        <div className="flex items-center justify-between">
          <SearchInput
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={t("page.companies.list.search.placeholder")}
          />
          <p className="pl-2 text-right text-grey-darker">
            {t("page.companies.list.company-count", { count: filteredCompanies.length })}
          </p>
        </div>
      }
    >
      <DeleteModal
        title={t("page.companies.list.actions.delete.title")}
        description={t("page.companies.list.actions.delete.description")}
        onDelete={(company) => onDelete(company.id)}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        id="location-code-modal"
        data-testid="location-code-modal"
        title={t("page.companies.list.location-code.refresh.title")}
        description={t("page.companies.list.location-code.refresh.description")}
        isOpen={!!locationCodeModalCompanyId}
        shouldCloseOnEsc
        onReject={() => setLocationCodeModalCompanyId(undefined)}
        rejectBtnProps={{
          text: t("page.companies.list.location-code.refresh.actions.cancel"),
        }}
        onResolve={async () => {
          try {
            if (locationCodeModalCompanyId) {
              refreshingLocationCodeHandlers.setTrue();
              await onRefreshLocationCode(locationCodeModalCompanyId);
            }
          } finally {
            refreshingLocationCodeHandlers.setFalse();

            setLocationCodeModalCompanyId(undefined);
          }
        }}
        resolveBtnProps={{
          text: t("page.companies.list.location-code.refresh.actions.confirm"),
        }}
        isLoading={isRefreshingLocationCode}
      />
      <div className="overflow-auto">
        {companies.length === 0 || filteredCompanies.length === 0 ? (
          <div className="rounded-lg bg-white p-5">
            <p>{companies.length === 0 ? t("page.companies.list.empty") : t("page.companies.list.no-result")}</p>
          </div>
        ) : (
          <Table<CompanyDto>
            table={tableInstance}
            data-testid="companies-list"
            getRowProps={() => ({
              "data-testid": "companies-item",
            })}
            showSorting
          />
        )}
      </div>
    </DocumentPaper>
  );
}
