import { useImageResolver } from "api/hooks/useImageResolver";
import type { ServicePartnerPageDetailsDto } from "api/types";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { IqbiDetailsView } from "modules/service-partners/components/IqbiDetails/IqbiDetailsView";
import { MyPupDetailsView } from "modules/service-partners/components/MyPupDetails/MyPupDetailsView";
import { ServiceDetailsBlockCard } from "modules/service-partners/components/ServiceDetails/ServiceDetailsBlockCard";
import { useMemo } from "react";
import { Mail, Phone } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  serviceDetails: ServicePartnerPageDetailsDto;
  onDelete: () => Promise<unknown>;
}

export function Layout({ serviceDetails, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } =
    useDeleteModal<DeleteRequestParams>("service-delete-modal");
  const resolveImage = useImageResolver();

  const actions = useMemo(() => {
    const result: ContextMenuAction[] = [];

    if (serviceDetails.canEdit) {
      result.push({
        callback: () => {
          navigate(routes.servicePartners.edit({ slug, id: serviceDetails.id }), { state: { from: "details" } });
        },
        text: t("common.action.edit"),
        dataTestId: "context-menu-edit-btn",
      });
    }
    if (serviceDetails.canDelete) {
      result.push({
        callback: () => {
          openDeleteModal({ id: serviceDetails.id });
        },
        text: t("common.action.delete"),
        dataTestId: "context-menu-delete-btn",
      });
    }

    return result;
  }, [serviceDetails.canEdit, serviceDetails.canDelete, serviceDetails.id, t, navigate, slug, openDeleteModal]);

  const bgImage = serviceDetails.headerImage || sessionUser.project.backgroundImage;
  const isServiceDescriptionShown =
    serviceDetails.type !== "iqbi" || serviceDetails.partnerProperties.iqbi?.status === "noConsent";

  return (
    <DocumentPaper
      theme="minimal-constrained"
      title={t("page.service-details.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.service.list.title"),
              to: routes.servicePartners.list({ slug }),
            },
            {
              name: t("page.service-details.breadcrumbs.self"),
            },
          ]}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <ServiceDetailsBlockCard
          title={serviceDetails.title}
          subtitle={serviceDetails.subtitle}
          backgroundImage={bgImage ? resolveImage(bgImage, "large") : bgPlaceholder}
          thumbnailImage={serviceDetails.profileImage?.url}
        >
          {(serviceDetails.phoneNumber || serviceDetails.email || actions.length) && (
            <div className="flex w-full items-center justify-end gap-4">
              {serviceDetails.phoneNumber && (
                <a
                  className="flex cursor-pointer items-center justify-center rounded-full bg-grey-lightest p-4 hover:bg-grey-lighter [&>svg]:stroke-aop-dark-blue hover:[&>svg]:stroke-blue-darkest"
                  href={`tel:${serviceDetails.phoneNumber}`}
                >
                  <Phone size={24} />
                </a>
              )}
              {serviceDetails.email && (
                <a
                  className="flex cursor-pointer items-center justify-around rounded-full bg-grey-lightest p-4 hover:bg-grey-lighter [&>svg]:stroke-aop-dark-blue hover:[&>svg]:stroke-blue-darkest"
                  href={`mailto:${serviceDetails.email}`}
                >
                  <Mail size={24} />
                </a>
              )}
              <ContextMenu actions={actions} />
            </div>
          )}
          {isServiceDescriptionShown && (
            <div
              className="prose whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html: serviceDetails.description!,
              }}
            />
          )}
        </ServiceDetailsBlockCard>

        {serviceDetails.type === "iqbi" && <IqbiDetailsView {...{ serviceDetails }} />}
        {serviceDetails.type === "myPup" && serviceDetails.partnerProperties.myPup && (
          <MyPupDetailsView {...{ serviceDetails }} />
        )}
      </div>

      <DeleteModal<DeleteRequestParams>
        title={t("model.service.action.delete.confirmation")}
        description={t("model.service.action.delete.description")}
        onDelete={onDelete}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}

interface DeleteRequestParams {
  id: string;
}
