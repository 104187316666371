import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ChatDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useConfig } from "hooks/useConfig";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponseType } from "types/api-types";

import type { ChatsListLayoutProps } from "./Layout";

interface ChatListLoaderProps {
  children: (props: ChatsListLayoutProps) => React.ReactNode;
}

const CHATS_PAGE = 10;

export function Loader(props: ChatListLoaderProps): React.ReactNode {
  const { setting: useGroupChats } = useConfig("useGroupChats");
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();

  const [chats, setChats] = useState<ChatDto[]>([]);

  const { signalRConnection } = useSignalRHub("notification-hub", {
    query: `userId=${sessionUser.id}`,
    disabled: !useGroupChats,
  });

  const {
    data: chatsData,
    hasNextPage: hasMoreChats,
    refetch: refetchChats,
    fetchNextPage: fetchMoreChats,
    fetchPreviousPage: fetchPreviousChatPage,
    isPending: isLoadingChats,
    isFetchingNextPage: isLoadingMoreChats,
    error: chatsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_LIST(projectId),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsV2({ Offset: pageParam * CHATS_PAGE, Limit: CHATS_PAGE })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    getPreviousPageParam: () => {
      return 0;
    },
  });

  const onNewChatMessage = useCallback(async () => {
    await refetchChats();
  }, [refetchChats]);

  useSignalRSubscription(signalRConnection, "NewChatMessageNotification", onNewChatMessage);
  useSignalRSubscription(signalRConnection, "NewGroupChatCreatedNotification", onNewChatMessage);

  const chatState = sessionUser.chatEnabled;
  const notificationState = sessionUser.newChatMessageDashboardSound;

  const toggleChats = useMutation({
    mutationFn: (state: boolean) => api.postChatsStateV1({ hideChat: !state }).then((x) => x.data),
    onMutate: (variables) => {
      queryClient.setQueryData<ApiResponseType<"getSelfV2"> | undefined>(QUERY_KEYS.SELF(projectId), (oldResponse) => {
        if (oldResponse) {
          return {
            ...oldResponse,
            data: {
              ...oldResponse.data,
              chatEnabled: variables,
            },
          };
        }
      });
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.chats.toggle-chats.error") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
  });

  const toggleChatNotification = useMutation({
    mutationFn: (state: boolean) =>
      api
        .patchNotificationSettingsSelfV1({ newChatMessageDashboardSound: { dashboardEnabled: state } })
        .then((x) => x.data),
    onMutate: (state) => {
      queryClient.setQueryData<ApiResponseType<"getSelfV2"> | undefined>(QUERY_KEYS.SELF(projectId), (oldResponse) => {
        if (oldResponse) {
          return {
            ...oldResponse,
            data: {
              ...oldResponse.data,
              newChatMessageDashboardSound: state,
            },
          };
        }
      });
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.chats.toggle-notification.error") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
  });

  useEffect(() => {
    setChats(chatsData?.pages.flatMap((x) => x.items) ?? []);
  }, [chatsData]);

  const refreshChats = () => {
    queryClient.removeQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
    void fetchPreviousChatPage();
  };

  const error = chatsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    chats,
    hasMoreChats,
    fetchMoreChats,
    refreshChats,
    isLoadingChats,
    isLoadingMoreChats,
    chatState,
    toggleChats: toggleChats.mutateAsync,
    notificationState,
    toggleChatNotification: toggleChatNotification.mutateAsync,
  });
}
