import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ApiClientProvider } from "api/ApiClientProvider";
import { AuthenticationTokenProvider } from "authentication/AuthenticationTokenProvider";
import { LogInRedirect } from "authentication/LogInRedirect";
import { LogoutRedirect } from "authentication/LogoutRedirect";
import { MsalProvider as AzureMsalProvider } from "authentication/MsalProvider";
import { ErrorPage } from "components/Error/ErrorPage";
import { FlashToastProvider } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { NavigationSidebar } from "components/NavigationSidebar/NavigationSidebar";
import { createQueryClient } from "helpers/query-client";
import { getRecentProject } from "helpers/recent-project";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useQueryParam } from "hooks/useQueryParam";
import { useSlug } from "hooks/useSlug";
import { addressesRoute } from "modules/addresses/router";
import { adminRoute } from "modules/admin/router";
import { adminNotificationsRoute } from "modules/admin-notifications/router";
import { analyticsRoute } from "modules/analytics/router";
import { automatedSurveyQueuesRoute } from "modules/automated-surveys/router";
import { bookingsRoute } from "modules/bookings/router";
import { buildingsRoute } from "modules/buildings/router";
import { calendarRoute } from "modules/calendar/router";
import { chatsRoute } from "modules/chats/router";
import { helpCategoriesRoute, interestGroupsRoute } from "modules/community-groups/router";
import { companyRoute } from "modules/companies/router";
import { documentsRoute } from "modules/documents/router";
import { eventsRoute } from "modules/events/router";
import { homeRoute } from "modules/home/router";
import { messagesRoute } from "modules/messages/router";
import { onboardingFlowRoute } from "modules/onboarding-flow/router";
import { onboardingScreensRoute } from "modules/onboarding-screens/router";
import { portfolioRoute } from "modules/portfolio/router";
import { projectsRoute } from "modules/project/router";
import { QuickReplyMessagePage } from "modules/quick-reply/pages/QuickReplyMessagePage";
import { QuickReplyRepairRequestPage } from "modules/quick-reply/pages/QuickReplyRepairRequestPage";
import { registrationRoute } from "modules/registration/router";
import { reservationsRoute } from "modules/reservations/router";
import { rolesRoute } from "modules/roles/router";
import { servicePartnersRoute } from "modules/service-partners/router";
import { signInSignUpRoute } from "modules/sign-in-sign-up/router";
import { surveysRoute } from "modules/surveys/router";
import { alertsRoute } from "modules/system-settings/alerts/router";
import { automatedSurveysRoute } from "modules/system-settings/automated-surveys/router";
import { platformGroupsRoute } from "modules/system-settings/platform-groups/router";
import { projectConnectionsRoute } from "modules/system-settings/project-connections/router";
import { ticketCategoriesRoute } from "modules/ticket-categories/router";
import { ticketStatusesRoute } from "modules/ticket-statuses/router";
import { useClearTicketsCache } from "modules/tickets/filters";
import { ticketsRoute } from "modules/tickets/router";
import { usersRoute } from "modules/users/router";
import { DateTimePickerConfigProvider } from "providers/DateTimePickerConfigProvider";
import { ForbiddenPopupProvider } from "providers/ForbiddenPopupProvider";
import { StopGlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import { IntercomProvider } from "providers/IntercomProvider";
import { ProjectLoader } from "providers/ProjectLoader";
import { PusherProvider } from "providers/PusherProvider";
import { SessionUserLoader } from "providers/SessionUserLoader";
import { Suspense, useEffect } from "react";
import type { RouteObject } from "react-router-dom";
import { createBrowserRouter, Outlet, useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import {
  AuthenticatedTranslationsProvider,
  BrowserLanguageTranslationsProvider,
} from "translations/TranslationsProvider";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    element: (
      <GlobalProviders>
        <Outlet />
      </GlobalProviders>
    ),
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { path: routes.logout.PATH, element: <LogoutRedirect /> },
          { path: routes.quickReplyMessage.PATH, element: <QuickReplyMessagePage /> },
          { path: routes.quickReplyRepairRequest.PATH, element: <QuickReplyRepairRequestPage /> },
          {
            path: routes.signInSignUp.ROOT,
            element: (
              <BrowserLanguageTranslationsProvider>
                <StopGlobalLoadingSpinner />
                <Outlet />
              </BrowserLanguageTranslationsProvider>
            ),
            children: signInSignUpRoute,
          },
          {
            path: routes.registration.ROOT,
            element: (
              <LogInRedirect>
                <AuthenticationTokenProvider>
                  <BrowserLanguageTranslationsProvider>
                    <StopGlobalLoadingSpinner />
                    <Outlet />
                  </BrowserLanguageTranslationsProvider>
                </AuthenticationTokenProvider>
              </LogInRedirect>
            ),
            children: registrationRoute,
          },
          {
            element: (
              <LogInRedirect>
                <AuthenticationTokenProvider>
                  <ProjectLoader>
                    <Outlet />
                  </ProjectLoader>
                </AuthenticationTokenProvider>
              </LogInRedirect>
            ),
            children: [
              { path: "/", element: <PortfolioRedirect /> },
              { path: "/@", element: <PortfolioRedirect /> },
              {
                path: routes.onboardingFlow.ROOT,
                element: (
                  <LoggedInProviders>
                    <Outlet />
                  </LoggedInProviders>
                ),
                children: onboardingFlowRoute,
              },
              {
                element: (
                  <MainLayout>
                    <LoggedInProviders>
                      <Outlet />
                    </LoggedInProviders>
                  </MainLayout>
                ),
                children: [
                  { path: routes.projectConnections.ROOT, children: projectConnectionsRoute },
                  { path: routes.automatedSurveys.ROOT, children: automatedSurveysRoute },
                  { path: routes.platformGroups.ROOT, children: platformGroupsRoute },
                  { path: routes.alerts.ROOT, children: alertsRoute },
                  { path: routes.portfolio.ROOT, children: portfolioRoute },
                  {
                    path: "/@/:slug",
                    children: [
                      {
                        errorElement: <ErrorPage />,
                        children: [
                          { path: routes.admin.ROOT, children: adminRoute },
                          { path: routes.adminNotifications.ROOT, children: adminNotificationsRoute },
                          { path: routes.ticketCategories.ROOT, children: ticketCategoriesRoute },
                          { path: routes.ticketStatuses.ROOT, children: ticketStatusesRoute },
                          { path: routes.tickets.ROOT, children: ticketsRoute },
                          { path: routes.events.ROOT, children: eventsRoute },
                          { path: routes.calendar.ROOT, children: calendarRoute },
                          { path: routes.bookings.ROOT, children: bookingsRoute },
                          { path: routes.reservations.ROOT, children: reservationsRoute },
                          { path: routes.roles.ROOT, children: rolesRoute },
                          { path: routes.helpCategories.ROOT, children: helpCategoriesRoute },
                          { path: routes.interestGroups.ROOT, children: interestGroupsRoute },
                          { path: routes.servicePartners.ROOT, children: servicePartnersRoute },
                          { path: routes.documents.ROOT, children: documentsRoute },
                          { path: routes.analytics.ROOT, children: analyticsRoute },
                          { path: routes.addresses.ROOT, children: addressesRoute },
                          { path: routes.messageFeed.ROOT, children: messagesRoute },
                          { path: routes.projects.ROOT, children: projectsRoute },
                          { path: routes.buildings.ROOT, children: buildingsRoute },
                          { path: routes.companies.ROOT, children: companyRoute },
                          { path: routes.onboardingScreens.ROOT, children: onboardingScreensRoute },
                          { path: routes.surveys.ROOT, children: surveysRoute },
                          { path: routes.automatedSurveyQueues.ROOT, children: automatedSurveyQueuesRoute },
                          { path: routes.chats.ROOT, children: chatsRoute },
                          { path: routes.users.ROOT, children: usersRoute },
                          { path: routes.home.ROOT, children: homeRoute },
                          { path: "", element: <RedirectToRoute to={routes.home.home} /> },
                          { path: "*", element: <ErrorPage status={404} /> },
                        ],
                      },
                    ],
                  },

                  // Legacy paths. These are not used anymore, but we need to redirect them to the new paths.
                  { path: deslug(routes.admin.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.ticketCategories.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.ticketStatuses.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.tickets.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.events.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.roles.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.helpCategories.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.interestGroups.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.servicePartners.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.documents.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.analytics.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.addresses.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.messageFeed.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.projects.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.buildings.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.companies.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.onboardingScreens.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.surveys.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.automatedSurveyQueues.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.chats.ROOT), element: <SlugRedirect /> },
                  { path: deslug(routes.users.ROOT), element: <SlugRedirect /> },
                  { path: "/home", element: <SlugRedirect /> },
                  { path: "*", element: <ErrorPage status={404} /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
] satisfies RouteObject[]);

/**
 * Providers for all pages
 */
function GlobalProviders({ children }: { children: React.ReactElement }): React.ReactNode {
  return (
    <QueryClientProvider client={queryClient}>
      <FlashToastProvider>
        <AzureMsalProvider>
          <ApiClientProvider>
            <AuthenticatedTranslationsProvider>
              <ForbiddenPopupProvider>{children}</ForbiddenPopupProvider>
            </AuthenticatedTranslationsProvider>
          </ApiClientProvider>
        </AzureMsalProvider>
      </FlashToastProvider>
      {import.meta.env.VITE_REACT_QUERY_DEVTOOLS === "true" && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

/**
 * Providers that require authentication and a project
 */
function LoggedInProviders({ children }: { children: React.ReactNode }): React.ReactNode {
  useClearTicketsCache();

  return (
    <SessionUserLoader isRoot>
      <PusherProvider>
        <IntercomProvider>
          <DateTimePickerConfigProvider>
            <StopGlobalLoadingSpinner />
            {children}
          </DateTimePickerConfigProvider>
        </IntercomProvider>
      </PusherProvider>
    </SessionUserLoader>
  );
}

function MainLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="relative grid h-full grid-rows-[min-content,1fr] md:flex md:flex-row">
      <NavigationSidebar />

      <main className="min-h-full w-full overflow-auto @container" id="main">
        <div className="mx-auto size-full max-w-[110rem]">
          <Suspense fallback={<FullSizeLoader withPadding />}>{children}</Suspense>
        </div>
      </main>
    </div>
  );
}

function PortfolioRedirect() {
  const { data: projects, error } = useConnectedProjects();
  const navigate = useNavigate();

  useEffect(() => {
    if (!projects) {
      return;
    }

    // If you're admin in at least one project
    if (projects.some((x) => x.userRole !== "resident")) {
      navigate(routes.portfolio.overview(), { replace: true });
    } else {
      navigate(routes.home.home({ slug: projects[0].slug }), { replace: true });
    }
  }, [navigate, projects]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  return null;
}

function deslug<T extends `/@/:slug${string}`>(path: T): T extends `/@/:slug${infer Rest}` ? Rest : never {
  return path.replace("/@/:slug", "") as any;
}

function SlugRedirect() {
  const { data: projects, error } = useConnectedProjects();
  const location = useLocation();
  const navigate = useNavigate();
  const [projectIdQueryParam] = useQueryParam("projectId");

  useEffect(() => {
    if (!projects) {
      return;
    }

    if (projects.length > 0) {
      const slugFromQueryParam = projectIdQueryParam
        ? projects.find((x) => x.id === projectIdQueryParam)?.slug
        : undefined;
      const slug = slugFromQueryParam || getRecentProject(projects).slug;

      navigate(`/@/${slug}${location.pathname}`);
    } else {
      navigate("/");
    }
  }, [location.pathname, navigate, projectIdQueryParam, projects]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  return null;
}

function RedirectToRoute({ to }: { to: (data: { slug: string }) => string }) {
  const navigate = useNavigate();
  const slug = useSlug();

  useEffect(() => {
    navigate(to({ slug }), { replace: true });
  }, [to, slug, navigate]);

  return null;
}

const queryClient = createQueryClient();
