import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ServicePartnerPageDetailsDto } from "api/types";
import { Button } from "components/Button/Button";
import { getFromConfig } from "helpers/config";
import { useSlug } from "hooks/useSlug";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { ServiceDetailsBlockCard } from "../ServiceDetails/ServiceDetailsBlockCard";

interface MyPupDetailsViewProps {
  serviceDetails: ServicePartnerPageDetailsDto;
}

export function MyPupDetailsView({ serviceDetails }: MyPupDetailsViewProps): React.ReactNode {
  const api = useApi();
  const slug = useSlug();
  const { t } = useTranslation();

  const registerMyPup = useMutation({ mutationFn: () => api.postServicepartnersMypupRegisterV1() });
  const partnerProperties = serviceDetails.partnerProperties.myPup!;

  useEffect(() => {
    const url = new URL(window.location.href);
    const myPupLoginUrl = url.searchParams.get("com_return_url");

    if (myPupLoginUrl) {
      if (!partnerProperties?.isRegistered) {
        registerMyPup.mutate();
      }
      window.open(myPupLoginUrl, "_self");
    }
  }, [partnerProperties?.isRegistered, registerMyPup]);

  async function createMyPupUrl() {
    const dashboardRootUrl = await getFromConfig("newDashboardRootUrl");
    const baseUrl = "https://login.mypup.app/LoginCommunity";

    const queryParams = {
      email: encodeURIComponent(partnerProperties.email),
      uuid: encodeURIComponent(partnerProperties.uuid),
      auth: encodeURIComponent(partnerProperties.authKey),
      first: encodeURIComponent(partnerProperties.firstName),
      last: encodeURIComponent(partnerProperties.lastName),
      phone: partnerProperties.phoneNumber ? encodeURIComponent(partnerProperties.phoneNumber) : "",
      pup: partnerProperties.pup ? encodeURIComponent(partnerProperties.pup) : "",
      lang: encodeURIComponent(partnerProperties.language),
      returnUrl: dashboardRootUrl
        ? encodeURIComponent(`${dashboardRootUrl}${routes.servicePartners.details({ slug, id: serviceDetails.id })}`)
        : "",
    };

    const myPupUrl = `${baseUrl}?${Object.entries(queryParams)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;
    window.open(myPupUrl, "_blank");
  }

  return (
    <ServiceDetailsBlockCard title={t("page.service-details.mypup.title")}>
      <Button styling="primary" onClick={createMyPupUrl}>
        {t("page.service-details.mypup.access")}
      </Button>
    </ServiceDetailsBlockCard>
  );
}
