import type { DocumentsRootFolderDto } from "api/types";
import { Button } from "components/Button/Button";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { RootFolder } from "modules/documents/components/RootFolder";
import { canManageRootFolders } from "modules/documents/permissions";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  rootList: DocumentsRootFolderDto[];
  onDeleteFolder: (param: RootFolderDeleteParam) => Promise<unknown>;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps, openDeleteModal } = useDeleteModal<RootFolderDeleteParam>("document-root-delete-modal");

  return (
    <DocumentPaper
      title={t("page.document.root.list.title")}
      subTitle={t("page.document.root.list.description")}
      actions={
        useResolvedPermission(canManageRootFolders) ? (
          <Button type="link" href={routes.documents.rootCreate({ slug })} data-testid="document-root-create-page">
            {t("page.document.root.list.action.new")}
          </Button>
        ) : null
      }
      theme="minimal"
    >
      <ul
        className="grid grid-cols-[repeat(auto-fit,1fr)] gap-6 sm:grid-cols-[repeat(auto-fit,minmax(500px,1fr))]"
        data-testid="documents-root-list"
      >
        {props.rootList.map((folder) => (
          <li key={folder.id}>
            <RootFolder folder={folder} onDelete={openDeleteModal} />
          </li>
        ))}
      </ul>
      <DeleteModal<RootFolderDeleteParam>
        title={t("model.document.root.action.delete.confirmation")}
        description={t("model.document.root.action.delete.description")}
        onDelete={props.onDeleteFolder}
        {...componentProps}
      />
    </DocumentPaper>
  );
}

type RootFolderDeleteParam = string;
