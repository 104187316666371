import type { ResidentEngagementMonthlyDto, ResidentTimeEngagementDto } from "api/types";
import { Button } from "components/Button/Button";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { Capture1, Subtitle2 } from "components/Text/Text";
import { Capture2 } from "components/Text/Text";
import { useSlug } from "hooks/useSlug";
import { BarChart } from "modules/analytics/components/BarChart";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import { Stat } from "modules/analytics/components/Stat";
import type { DateRangeOption } from "modules/analytics/util";
import { formatChartDate } from "modules/analytics/util";
import { useMemo } from "react";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { NonEmptyArray } from "types/utility-types";

export interface LayoutProps {
  engagementDetails: ResidentEngagementMonthlyDto[];
  engagementOverview: ResidentTimeEngagementDto[];
  isRefetchingEngagementData: boolean;
  selectedDateRange: DateRangeOption;
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
  onDateRangeChange: (dateRange: DateRangeOption) => void;
}

const BENCHMARK_VALUE = 70;

export function Layout({
  engagementDetails,
  engagementOverview,
  isRefetchingEngagementData,
  selectedDateRange,
  dateRangeOptions,
  onDateRangeChange,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  const adoptionDataPoints = useMemo(
    () =>
      engagementDetails
        ? engagementDetails.map((point) => ({ x: formatChartDate(point.range), y: point.percentage }))
        : [],
    [engagementDetails],
  );

  const benchmark = useMemo(
    () =>
      engagementDetails
        ? engagementDetails.map((point) => ({ x: formatChartDate(point.range), y: BENCHMARK_VALUE }))
        : [],
    [engagementDetails],
  );

  const noEngagementDataAvailable = !engagementDetails || engagementDetails.every((x) => x.percentage === 0);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.analytics-details.engagement.title")}
      subTitle={
        <Button
          type="link"
          styling="tertiary"
          className="pl-0 font-normal text-aop-basic-blue"
          href={routes.analytics.overview({ slug })}
        >
          <ChevronLeft size={18} />
          {t("page.analytics-details.back")}
        </Button>
      }
      header={
        <div className="flex items-center gap-2 py-4">
          <Subtitle2>{t("page.analytics-details.time-range.label")}</Subtitle2>
          <Select
            items={dateRangeOptions}
            selected={selectedDateRange}
            onChange={onDateRangeChange}
            renderOption={(x) => x.name}
            keySelector={(x) => x.value}
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <Card className="order-1 h-fit lg:col-span-2">
          <CardHeader title={t("page.analytics-details.engagement.chart.engagement.title")}></CardHeader>
          {isRefetchingEngagementData ? (
            <LoadingIcon className="mx-auto w-8" />
          ) : noEngagementDataAvailable ? (
            <NoData error={noEngagementDataAvailable} />
          ) : (
            <div className="flex flex-col gap-4">
              <Capture2 className="text-grey-darker">
                {t("page.analytics-details.engagement.chart.engagement.description")}
              </Capture2>
              <div className="w-full">
                <BarChart
                  type="date"
                  height={250}
                  primaryData={adoptionDataPoints}
                  benchmark={benchmark}
                  formatYTick={(y) => y}
                  formatXTick={(x) => x}
                  maxY={100}
                  yLabel={t("page.analytics-details.engagement.chart.engagement.y-axis")}
                  showLegend={false}
                  renderLabel={(data) => (
                    <div className="whitespace-pre-line">
                      {t("page.analytics-details.engagement.chart.engagement-benchmark.tooltip", {
                        percentage: data.primary?.y,
                      })}
                    </div>
                  )}
                />
              </div>
              <div className="mx-auto grid w-1/2 grow grid-cols-2 gap-3">
                <Stat
                  title={t("page.analytics-details.engagement.card.day")}
                  value={`${engagementOverview.filter((x) => x.type === "last24Hours")[0].engagementPercentage}%`}
                />
                <Stat
                  title={t("page.analytics-details.engagement.card.week")}
                  value={`${engagementOverview.filter((x) => x.type === "last7Days")[0].engagementPercentage}%`}
                />
              </div>
            </div>
          )}
        </Card>
        <div className="flex flex-col gap-4">
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.engagement.information.title")} />
            <Capture1>{t("page.analytics-details.engagement.information.subtitle")}</Capture1>
            <ul className="list-disc p-4">
              {t("page.analytics-details.engagement.information.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`information-${idx}`}>{item}</li>)}
            </ul>
          </Card>
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.engagement.best-practices.title")} />
            <Capture1>{t("page.analytics-details.engagement.best-practices.subtitle")}</Capture1>
            <ul className="list-disc p-4">
              {t("page.analytics-details.engagement.best-practices.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`best-practices-${idx}`}>{item}</li>)}
            </ul>
          </Card>
        </div>
      </div>
    </DocumentPaper>
  );
}
