import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { FeelingAtHomeQuestionResponseDto, FeelingAtHomeSurveyQuestionDto } from "api/types";
import { Button } from "components/Button/Button";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { ErrorPage } from "components/Error/ErrorPage";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal } from "components/Modal/Modal";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { FeelingAtHomeSurveyQuestionGroupCard } from "components/SurveyQuestionGroupAnswerCard/FeelingAtHomeSurveyQuestionGroupCard";
import { Table } from "components/Table/Table";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { parseISO, startOfMonth, subMonths } from "date-fns";
import { asUtc, tryParse } from "helpers/date";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useQueryParam } from "hooks/useQueryParam";
import { FeelingAtHomeExportModal } from "modules/surveys/components/FeelAtHomeExportModal";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { Star } from "react-feather";
import { useTranslation } from "react-i18next";
import type { ApiQueryParams } from "types/api-types";

export function Layout(): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const api = useApi();

  const [dateQueryParam] = useQueryParam("date");

  const [selectedMonth, setSelectedMonth] = useState<Date>(() =>
    startOfMonth(tryParse("yyyy-MM", dateQueryParam) ?? subMonths(new Date(), 1)),
  );
  const [selectedQuestionData, setSelectedQuestionData] = useState<{
    data: FeelingAtHomeSurveyQuestionDto;
    number: number;
  } | null>(null);
  const [isQuestionModalOpen, questionModalHandler] = useBool(false);

  const [surveyExportModal, setSurveyExportModal] = useState<
    { open: true; month?: Date } | { open: false; month?: undefined }
  >({
    open: false,
  });

  const query: ApiQueryParams<"getAnalyticsFeelingAtHomeDetailsV1"> = { month: asUtc(selectedMonth).toISOString() };
  const {
    data: feelingAtHome,
    isFetching: isReloadingDetails,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.FEELING_AT_HOME_DETAILS(projectId, query),
    queryFn: () => api.getAnalyticsFeelingAtHomeDetailsV1(query),
    select: commonAPIDataSelector,
    placeholderData: keepPreviousData,
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!feelingAtHome) {
    return <FullSizeLoader />;
  }

  function handleOpenQuestionModal(idx: number) {
    setSelectedQuestionData({
      data: feelingAtHome!.questions[idx],
      number: idx + 1,
    });
    questionModalHandler.setTrue();
  }

  function handleCloseQuestionModal() {
    setSelectedQuestionData(null);
    questionModalHandler.setFalse();
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.feeling-at-home-details.title")}
      subTitle={t("page.feeling-at-home-details.subtitle")}
      actions={
        <ContextMenu
          actions={[
            {
              text: t("page.feeling-at-home-details.export.button.monthly"),
              callback: () => setSurveyExportModal({ open: true, month: selectedMonth }),
            },
            {
              text: t("page.feeling-at-home-details.export.button.all"),
              callback: () => setSurveyExportModal({ open: true }),
            },
          ]}
        >
          {(props) => (
            <Button
              title={t("component.context-menu.action.open")}
              isPressed={props.isOpen}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.openHandlers.toggle();
              }}
            >
              {t("page.feeling-at-home-details.export.button")}
            </Button>
          )}
        </ContextMenu>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          {sessionUser.isSuperAdmin ? (
            <>
              <FeelingAtHomeCard>
                <Subtitle2>{t("page.feeling-at-home-details.total-responses")}</Subtitle2>
                <Headline4>{feelingAtHome.totalResponseCount}</Headline4>
              </FeelingAtHomeCard>
              <FeelingAtHomeCard>
                <Subtitle2>{t("page.feeling-at-home-details.total-asked")}</Subtitle2>
                <Headline4>{feelingAtHome.totalAskedCount}</Headline4>
              </FeelingAtHomeCard>
            </>
          ) : null}
          {isReloadingDetails ? (
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          ) : (
            <>
              <FeelingAtHomeCard>
                <Subtitle2>{t("page.feeling-at-home-details.selected-month")}</Subtitle2>
                <MonthPicker
                  minDate={parseISO(feelingAtHome.startedAt)}
                  maxDate={new Date()}
                  value={selectedMonth}
                  onChange={setSelectedMonth}
                  placement="left"
                />
              </FeelingAtHomeCard>
              <FeelingAtHomeCard>
                <Subtitle2>{t("page.feeling-at-home-details.month-responses")}</Subtitle2>
                <Headline4>{feelingAtHome.questions[0]?.responseCount ?? 0}</Headline4>
              </FeelingAtHomeCard>
            </>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {isReloadingDetails ? (
            <LoadingIcon className="inset-0 col-span-2 mx-auto my-4 w-6" />
          ) : feelingAtHome.questions.length ? (
            feelingAtHome.questions.map((question, idx) => (
              <FeelingAtHomeSurveyQuestionGroupCard
                key={idx}
                order={idx + 1}
                question={question}
                onClickViewDetails={() => handleOpenQuestionModal(idx)}
              />
            ))
          ) : (
            <Subtitle2>{t("page.feeling-at-home-details.no-data")}</Subtitle2>
          )}
        </div>
      </div>
      <Modal isOpen={isQuestionModalOpen} onRequestClose={handleCloseQuestionModal} fullScreen>
        <QuestionDetailsModal question={selectedQuestionData} />
      </Modal>
      <FeelingAtHomeExportModal
        isOpen={surveyExportModal.open}
        month={surveyExportModal.month}
        onClose={() => setSurveyExportModal({ open: false })}
      />
    </DocumentPaper>
  );
}

function FeelingAtHomeCard({ children }: { children: React.ReactNode }): React.ReactNode {
  return <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-sm">{children}</div>;
}

interface QuestionDetailsModalProps {
  question: { data: FeelingAtHomeSurveyQuestionDto; number: number } | null;
}

function QuestionDetailsModal({ question }: QuestionDetailsModalProps): React.ReactNode {
  const { i18n, t } = useTranslation();

  const responses = useMemo(() => question?.data.responses || [], [question]);

  const columns = useMemo(() => {
    const helper = createColumnHelper<FeelingAtHomeQuestionResponseDto>();

    return [
      helper.accessor("rating", {
        header: t("page.feeling-at-home-details.question-details.table.header.rating"),
        cell: (cell) => (
          <span className="flex items-center gap-1 text-center">
            <span>{cell.getValue()}</span>
            <Star className="shrink-0 text-yellow" size={16} />
          </span>
        ),
        maxSize: 40,
      }),
      helper.accessor("answer", {
        header: t("page.feeling-at-home-details.question-details.table.header.answer"),
        cell: (cell) => <span className="block py-0.5">{cell.getValue()}</span>,
      }),
      helper.accessor("user", {
        header: t("page.feeling-at-home-details.question-details.table.header.user"),
        cell: (cell) => <span className="block truncate">{cell.getValue().fullName}</span>,
        maxSize: 100,
      }),
      helper.accessor("answeredAt", {
        header: t("page.feeling-at-home-details.question-details.table.header.date"),
        cell: (cell) => <span>{formatDate(i18n, "datetimeShort", cell.getValue())}</span>,
        maxSize: 50,
      }),
    ];
  }, [i18n, t]);

  const tableInstance = useReactTable<FeelingAtHomeQuestionResponseDto>({
    columns,
    data: responses,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col gap-4 px-6 py-4">
      <Headline4 as="h2">
        {t("component.surveys-question.details.question-number", { number: question?.number })}
      </Headline4>
      <div className="flex flex-col">
        <Headline4>{question?.data.ratingQuestionText}</Headline4>
        <span className="text-grey-dark">({question?.data.openQuestionText})</span>
      </div>
      <Capture2>
        {t("page.feeling-at-home-details.question-details.responses", { count: question?.data.responses.length })}
      </Capture2>

      <Table
        table={tableInstance}
        data-testid="feeling-at-home-answer-list"
        getRowProps={() => ({
          "data-testid": "feeling-at-home-answer-item",
        })}
      />
    </div>
  );
}
