import { Collapsible as ArkCollapsible, Menu as ArkMenu, Portal as ArkPortal } from "@ark-ui/react";
import { autoUpdate, FloatingPortal, useFloating } from "@floating-ui/react";
import { ChevronIcon } from "components/Icons/Icons";
import { AnimatePresence, motion } from "framer-motion";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSidebarManager } from "hooks/useSidebar";
import type React from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { sidebarItemLabelAnimation } from "./animations";
import { SidebarItem, SidebarItemFloatingTooltip, sidebarItemVariants } from "./SidebarItem";
import type { SidebarBaseItemType, SidebarGroupedItemType } from "./types";

interface SidebarGroupedItemProps extends SidebarGroupedItemType {}

export function SidebarGroupedItem({ label, icon, items, ...props }: SidebarGroupedItemProps): React.ReactNode {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isTooltipVisible, tooltipVisibilityHandlers] = useBool(false);

  const [isActive, activityHandlers] = useBool(false);
  const [isExpanded, expandHandlers] = useBool(isActive);
  const [isMenuVisible, menuVisibilityHandlers] = useBool(false);

  const { refs, floatingStyles } = useFloating({ whileElementsMounted: autoUpdate, placement: "right" });
  const location = useLocation();
  const { isCollapsed } = useSidebarManager();
  const isDesktop = useScreenIsBiggerThan("md");
  const hasPermission = usePermission();

  useEffect(() => {
    const isAnyItemActive = items.some((x) => location.pathname.includes(x.to));

    activityHandlers.set(isAnyItemActive);
    expandHandlers.set(isAnyItemActive);
  }, [activityHandlers, expandHandlers, items, location.pathname]);

  // Reset menu when sidebar is collapsed
  useEffect(() => {
    if (!isCollapsed) return;

    expandHandlers.set(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  useEffect(() => {
    if (!isMenuVisible) return;

    menuVisibilityHandlers.set(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const showLabel = ({ withDelay }: { withDelay: boolean }) => {
    if (withDelay) {
      const timer = setTimeout(() => {
        tooltipVisibilityHandlers.set(true);
      }, 300);

      setTimer(timer);
    } else {
      tooltipVisibilityHandlers.set(true);
    }
  };

  const hideLabel = () => {
    if (timer) {
      clearTimeout(timer);
    }

    tooltipVisibilityHandlers.set(false);
  };

  const filteredItems = items.filter((x) => (x.permission ? hasPermission(x.permission) : true));

  if (filteredItems.length === 0) return null;

  if (filteredItems.length === 1) return <SidebarItem {...items[0]} />;

  if (isCollapsed) {
    return (
      <>
        <ArkMenu.Root
          positioning={{
            placement: "right",
          }}
          open={isMenuVisible}
          onOpenChange={menuVisibilityHandlers.toggle}
        >
          <ArkMenu.Trigger asChild>
            <button
              className={sidebarItemVariants({
                isActive: isActive || isMenuVisible,
              })}
              data-testid={props["data-testid"]}
              onMouseEnter={isCollapsed ? () => showLabel({ withDelay: true }) : undefined}
              onMouseLeave={isCollapsed ? hideLabel : undefined}
              ref={refs.setReference}
              // Required for screenreader when sidebar is collapsed
              aria-label={label}
            >
              <div className="flex items-center gap-2">
                {icon}
                <AnimatePresence>
                  {!isCollapsed && (
                    <motion.span
                      className="block whitespace-nowrap"
                      variants={isDesktop ? sidebarItemLabelAnimation : undefined}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                    >
                      {label}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
              {!isCollapsed && <ChevronIcon orientation={isExpanded ? "down" : "right"} className="mr-0.5 w-3.5" />}
            </button>
          </ArkMenu.Trigger>
          <ArkPortal>
            <ArkMenu.Positioner className="!z-50">
              <ArkMenu.Content asChild>
                <div className="rounded-lg border border-grey-lightest bg-white p-2 shadow-lg focus-visible:outline-none data-[state=closed]:animate-fade-out data-[state=open]:animate-fade-in">
                  <ArkMenu.ItemGroup className="flex flex-col gap-2">
                    <ArkMenu.ItemGroupLabel className="p-2 font-semibold">{label}</ArkMenu.ItemGroupLabel>
                    <ul className="flex w-full flex-col gap-2">
                      {items.map((item, index) => (
                        <SidebarGroupedItemFloatingMenuItem
                          label={item.label}
                          to={item.to}
                          exact={item.exact}
                          data-testid={item["data-testid"]}
                          key={`${item.label}-${index}`}
                        />
                      ))}
                    </ul>
                  </ArkMenu.ItemGroup>
                </div>
              </ArkMenu.Content>
            </ArkMenu.Positioner>
          </ArkPortal>
        </ArkMenu.Root>

        {/* Floating tooltip */}
        <AnimatePresence>
          {!isMenuVisible && isTooltipVisible && (
            <FloatingPortal>
              <SidebarItemFloatingTooltip
                label={label}
                ref={refs.setFloating}
                style={floatingStyles}
                isVisible={isTooltipVisible}
              />
            </FloatingPortal>
          )}
        </AnimatePresence>
      </>
    );
  }

  return (
    <ArkCollapsible.Root open={isExpanded} onOpenChange={expandHandlers.toggle}>
      <ArkCollapsible.Trigger asChild>
        <button
          className={twJoin(
            "flex h-10 w-full items-center justify-between rounded-lg p-3 transition-colors duration-200 ease-linear",
            "focus:outline-none focus-visible:bg-aop-basic-blue-lightest md:hover:bg-aop-basic-blue-lightest",
            isActive || isExpanded ? "bg-aop-basic-blue-lightest font-semibold text-aop-basic-blue" : "font-medium",
          )}
          data-testid={props["data-testid"]}
        >
          <div className="flex items-center gap-2">
            {icon}
            <motion.span
              className="block whitespace-nowrap"
              variants={sidebarItemLabelAnimation}
              initial={isDesktop ? "hidden" : "visible"}
              animate="visible"
              exit="hidden"
            >
              {label}
            </motion.span>
          </div>
          <ChevronIcon orientation={isExpanded ? "down" : "right"} className="mr-0.5 w-3.5" />
        </button>
      </ArkCollapsible.Trigger>
      <ArkCollapsible.Content className="overflow-hidden data-[state=closed]:animate-collapse-y data-[state=open]:animate-expand-y">
        <ul className="flex w-full flex-col gap-2 py-2 pl-8">
          {filteredItems.map((item, index) => (
            <SidebarItem key={`${item.label}-${index}`} {...item} />
          ))}
        </ul>
      </ArkCollapsible.Content>
    </ArkCollapsible.Root>
  );
}

interface SidebarGroupedItemMenuItemProps
  extends Pick<SidebarBaseItemType, "label" | "to" | "exact" | "data-testid" | "onClick"> {}

export function SidebarGroupedItemFloatingMenuItem({
  label,
  exact,
  to,
  onClick,
}: SidebarGroupedItemMenuItemProps): React.ReactNode {
  return (
    <ArkMenu.Item value={label} asChild>
      {/* Hide focus-visible styling, rely on `data-highlighted` */}
      <NavLink
        className={twResolve(
          sidebarItemVariants(),
          "focus-visible:bg-transparent data-[highlighted]:bg-aop-basic-blue-lightest",
        )}
        tabIndex={-1}
        end={exact}
        {...{ to, onClick }}
      >
        <span className="block whitespace-nowrap">{label}</span>
      </NavLink>
    </ArkMenu.Item>
  );
}
