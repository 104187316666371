import type { ButtonBaseProps } from "components/Button/Button";
import { buttonVariants } from "components/Button/Button";
import { Tooltip } from "components/Tooltip/Tooltip";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import { NavLink } from "react-router-dom";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

export type IconButtonProps = Omit<ButtonBaseProps, "title"> & {
  title: string;
  styling?: IconButtonVariants["styling"];
  size?: IconButtonVariants["size"];
  withTooltip?: boolean;
  isCircular?: boolean;
} & (
    | {
        type: "link";
        href: string;
        state?: any;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
      }
    | {
        type?: "button" | "submit";
        href?: never;
        state?: never;
        onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
      }
  );

export function IconButton({
  title,
  children,
  size,
  type,
  href,
  styling = "secondary",
  className,
  isPressed,
  disabled,
  "data-testid": dataTestId,
  withTooltip = true,
  isCircular,
  onClick,
  ...props
}: IconButtonProps): React.ReactNode {
  const propsCommon = {
    "data-testid": dataTestId,
    "aria-label": title,
    "aria-pressed": isPressed,
    className: twResolve(
      iconButtonVariants({
        styling,
        size,
      }),
      "flex items-center justify-center px-0",
      isCircular && "rounded-full",
      className,
    ),
  };

  if (type === "link") {
    return (
      <Tooltip tooltip={withTooltip ? title : undefined}>
        <NavLink to={href} {...{ ...propsCommon }} state={props.state}>
          {children}
        </NavLink>
      </Tooltip>
    );
  }

  return (
    <Tooltip tooltip={withTooltip ? title : undefined}>
      <button type="button" {...{ ...propsCommon, disabled, onClick }}>
        {children}
      </button>
    </Tooltip>
  );
}

const iconButtonVariants = tv({
  extend: buttonVariants,
  variants: {
    size: {
      sm: "size-8",
      md: "size-10",
    },
  },
});

type IconButtonVariants = VariantProps<typeof iconButtonVariants>;
