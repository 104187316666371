import type { BookableAssetDeletedResult, BookableAssetDto, BookableAssetStatisticsDto } from "api/types";
import { Button } from "components/Button/Button";
import { CardGrid } from "components/CardGrid/CardGrid";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Subtitle2 } from "components/Text/Text";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canCreateBookableAssets } from "modules/bookings/permissions";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

import { BookableAssetCard } from "./components/BookableAssetCard";

export interface LayoutProps {
  assets: BookableAssetDto[];
  hasMoreAssets: boolean;
  isLoadingMoreAssets: boolean;
  fetchMoreAssets: () => void;
  getFutureBookings: (id: string) => Promise<BookableAssetStatisticsDto>;
  onDelete: (id: string) => Promise<BookableAssetDeletedResult>;
}

export function Layout({
  assets,
  hasMoreAssets,
  isLoadingMoreAssets,
  fetchMoreAssets,
  getFutureBookings,
  onDelete,
}: LayoutProps): React.ReactNode {
  const [assetBookings, setAssetBookings] = useState<number>(0);
  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-asset-modal");
  const hasPermission = usePermission();
  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreAssets && hasMoreAssets) {
        fetchMoreAssets();
      }
    }, [fetchMoreAssets, hasMoreAssets, isLoadingMoreAssets]),
  });

  const onOpenDeleteModal = useCallback(
    async (id: string) => {
      const { futureBookingsCount } = await getFutureBookings(id);
      setAssetBookings(futureBookingsCount);
      openDeleteModal(id);
    },
    [getFutureBookings, openDeleteModal],
  );

  const actions: Record<string, ContextMenuAction[]> = useMemo(() => {
    return assets.reduce(
      (acc, asset) => {
        acc[asset.id] = [];
        if (asset.canEdit) {
          acc[asset.id].push({
            dataTestId: "context-menu-edit-btn",
            text: t("common.action.edit"),
            callback: () => navigate(routes.bookings.editAsset({ slug, id: asset.id })),
          });
        }
        if (asset.canDelete) {
          acc[asset.id].push({
            dataTestId: "context-menu-delete-btn",
            text: t("common.action.delete"),
            callback: () => onOpenDeleteModal(asset.id),
          });
        }

        return acc;
      },
      {} as Record<string, ContextMenuAction[]>,
    );
  }, [assets, slug, t, navigate, onOpenDeleteModal]);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.bookings.list-assets.title")}
      subTitle={t("page.bookings.list-assets.subtitle")}
      actions={
        hasPermission(canCreateBookableAssets) ? (
          <Button type="link" href={routes.bookings.createAsset({ slug })} styling="primary">
            {t("page.bookings.list-assets.actions.new")}
          </Button>
        ) : !hasPermission((x) => x.assets.canViewSchedule) ? (
          <Button type="link" href={`${routes.calendar.list({ slug })}?tab=reservations`} styling="primary">
            {t("page.bookings.list-assets.actions.see-reservations")}
          </Button>
        ) : null
      }
    >
      <div className="flex w-full flex-col gap-4">
        <CardGrid>
          {assets.length === 0 ? (
            <Subtitle2>
              {sessionUser.isAdmin
                ? t("page.bookings.list-assets.no-assets.admin")
                : t("page.bookings.list-assets.no-assets.resident")}
            </Subtitle2>
          ) : (
            assets.map((asset) => (
              <BookableAssetCard
                data-testid="asset-card"
                key={asset.id}
                id={asset.id}
                actions={actions[asset.id]}
                images={asset.images}
                name={asset.name}
                description={asset.description}
                sizeSpecification={asset.sizeSpecification}
                pricePerHourSpecification={asset.pricePerHourSpecification}
                capacitySpecification={asset.capacitySpecification}
                locationSpecification={asset.locationSpecification}
                publishAt={asset.publishAt}
                unpublishAt={asset.canEdit ? asset.unpublishAt : undefined}
                isAudienceSet={asset.audience?.length > 0}
              />
            ))
          )}
        </CardGrid>
        {hasMoreAssets && (
          <div className="p-4" ref={loaderRef}>
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          </div>
        )}
      </div>

      <DeleteModal
        title={t("page.bookings.delete-asset.modal.title")}
        description={
          assetBookings === 0
            ? t("page.bookings.delete-asset.modal.description.no-bookings")
            : t("page.bookings.delete-asset.modal.description", { count: assetBookings })
        }
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
