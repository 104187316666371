import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { usePermission } from "hooks/usePermission";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

// 12 items per load to prevent orphan issue in the UI
// as it can either be 1, 2, 3 or 4 items per row
const ITEM_AMOUNT = 12;

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const hasPermission = usePermission();
  const { t } = useTranslation();

  const assetsQuery: ApiQueryParams<"getBookableAssetsV1"> = {
    HideUnpublished: !hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn),
  };

  const {
    data: assetsData,
    isPending: isPendingAssets,
    error: assetsError,
    hasNextPage: hasMoreAssets,
    fetchNextPage: fetchMoreAssets,
    isFetchingNextPage: isLoadingMoreAssets,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSETS_INFINITE(projectId, assetsQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsV1({
          ...assetsQuery,
          Offset: pageParam * ITEM_AMOUNT,
          Limit: ITEM_AMOUNT,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });
  const assetStatistics = useMutation({
    mutationFn: (id: string) => api.getBookableAssetsStatisticsV1(id).then((x) => x.data),
  });
  const deleteAsset = useMutation({
    mutationFn: (id: string) => api.deleteBookableAssetsByIdV1(id).then((x) => x.data),
    onSuccess: (_, id) => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.delete-asset.notification.success"),
      });

      queryClient.removeQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, id) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSETS(projectId) });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.delete-asset.notification.error"),
      }),
  });

  const assets = useMemo(() => assetsData?.pages.flatMap((x) => x.items ?? []) ?? [], [assetsData]);

  if (assetsError) {
    return <ErrorPage error={assetsError} />;
  }

  if (isPendingAssets) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    assets,
    hasMoreAssets,
    isLoadingMoreAssets,
    fetchMoreAssets,
    getFutureBookings: assetStatistics.mutateAsync,
    onDelete: deleteAsset.mutateAsync,
  });
}
