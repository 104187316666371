import type {
  ChatReplyDto,
  ConversationReplyDto,
  MessageV2Dto,
  ReminderStatusDto,
  SimpleProjectDto,
  UserDetailsWithAddressInterestsAndHelpDto,
} from "api/types";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Capture1, Capture2, Headline4 } from "components/Text/Text";
import { addDays, parseISO } from "date-fns";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useConfig } from "hooks/useConfig";
import { useSlug } from "hooks/useSlug";
import { ChatWindow } from "modules/chats/components/ChatWindow";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost";
import { ChatWindow as ChatWindowV1 } from "modules/users/components/ChatWindow";
import { UserDetails } from "modules/users/components/UserDetails";
import { useEffect, useState } from "react";
import { X as XIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export interface UserDetailsLayoutProps {
  userDetails: UserDetailsWithAddressInterestsAndHelpDto;
  userProjects: SimpleProjectDto[] | undefined;
  userPosts: MessageV2Dto[] | undefined;
  isLoadingUserPosts: boolean;
  onUserDelete: () => Promise<unknown>;
  onUserSetDeletionDate: (date: Date | undefined) => Promise<unknown>;
  chatReplies: ChatReplyDto[] | ConversationReplyDto[];
  hasMoreReplies: boolean | undefined;
  fetchMoreReplies: () => void;
  refreshReplies: () => void;
  isLoadingMoreReplies: boolean;
  sendChat: ({
    message,
    files,
  }: {
    message: string;
    files: FormImage[];
  }) => Promise<ChatReplyDto | ConversationReplyDto>;
  signUpReminders: ReminderStatusDto | undefined;
  sendReminder: () => void;
  isSubmittingModal: boolean;
  deletedBy?: UserDetailsWithAddressInterestsAndHelpDto;
}

export function Layout({
  userDetails,
  userProjects,
  userPosts,
  isLoadingUserPosts,
  onUserDelete,
  onUserSetDeletionDate,
  chatReplies,
  hasMoreReplies,
  fetchMoreReplies,
  refreshReplies,
  isLoadingMoreReplies,
  sendChat,
  signUpReminders,
  sendReminder,
  isSubmittingModal,
  deletedBy,
}: UserDetailsLayoutProps): React.ReactNode {
  const { setting: hasChatsV2Migrated } = useConfig("hasChatsV2Migrated");
  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const { id: userIdParam } = useParams<{ id: string }>();
  const [isDeleteModalOpen, deleteHandler] = useBool(false);
  const [isDeleteConfirmModalOpen, deleteConfirmHandler] = useBool(false);
  const [isDeleteDateModalOpen, deleteDateHandler] = useBool(false);
  const [deletionDate, setDeletionDate] = useState<DatePickerValue>(() => "");

  useEffect(() => {
    setDeletionDate(userDetails.deleteAfter ? parseISO(userDetails.deleteAfter) : "");
  }, [userDetails.deleteAfter]);

  function closeModals() {
    deleteHandler.setFalse();
    deleteConfirmHandler.setFalse();
    deleteDateHandler.setFalse();
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.user-detail.title")}
      subTitle={t("page.user-detail.subtitle")}
      actions={
        userDetails.canBeEdited || userDetails.canBeDeleted || sessionUser.isSuperAdmin ? (
          <>
            {sessionUser.isAdmin && userDetails.canBeDeleted && (
              <Button data-testid="delete-user-btn" styling="danger" onClick={deleteHandler.setTrue}>
                {t("page.user-detail.actions.delete-user")}
              </Button>
            )}
            {sessionUser.isAdmin && userDetails.canBeEdited && (
              <Button type="link" data-testid="edit-user-btn" href={routes.users.edit({ slug, id: userDetails.id })}>
                {t("page.user-detail.actions.edit-user-details")}
              </Button>
            )}
            {sessionUser.isSuperAdmin && !userDetails.deletedAt && (
              <Button
                type="link"
                data-testid="notification-settings-btn"
                href={routes.adminNotifications.otherUserSettings({ slug, userId: userIdParam! })}
                state={{ fromPage: routes.users.details({ id: userIdParam!, slug }) }}
                styling="secondary"
              >
                {t("page.user-detail.actions.edit-user-notifications")}
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      <div className="flex flex-col items-start gap-4 lg:flex-row">
        <div className="flex w-full grow flex-col gap-10">
          {sessionUser.id !== userIdParam && hasChatsV2Migrated && (
            <ChatWindow
              canChat={userDetails.chatEnabled && !userDetails.deletedAt}
              chatReplies={chatReplies as ChatReplyDto[]}
              hasMoreReplies={hasMoreReplies}
              fetchMoreReplies={fetchMoreReplies}
              refreshReplies={refreshReplies}
              isLoadingMoreReplies={isLoadingMoreReplies}
              sendChat={sendChat as (args: { message: string; files: FormImage[] }) => Promise<ChatReplyDto>}
              size="sm"
            />
          )}
          {sessionUser.id !== userIdParam && !hasChatsV2Migrated && (
            <ChatWindowV1
              canChatWithUser={userDetails.chatEnabled && !userDetails.deletedAt}
              chatMessages={chatReplies as ConversationReplyDto[]}
              hasMoreChatMessages={hasMoreReplies}
              fetchMoreChatMessages={fetchMoreReplies}
              refreshChatMessages={refreshReplies}
              isLoadingMoreChatMessages={isLoadingMoreReplies}
              sendChat={sendChat as (args: { message: string; files: FormImage[] }) => Promise<ConversationReplyDto>}
            />
          )}
          <div className="flex flex-col gap-3">
            {isLoadingUserPosts ? (
              <FullSizeLoader />
            ) : (
              <>
                <Capture1>{t("page.user-detail.posts-section")}</Capture1>
                {userPosts && userPosts.length > 0 ? (
                  <div className="flex flex-col gap-5">
                    {userPosts.map((post) => (
                      <CommunityPost key={post.id} message={post} isExpanded={false} />
                    ))}
                  </div>
                ) : (
                  <Capture2>{t("page.user-detail.no-posts")}</Capture2>
                )}
              </>
            )}
          </div>
        </div>
        <UserDetails
          user={userDetails}
          projects={userProjects ?? []}
          reminderStatus={signUpReminders}
          sendReminder={sendReminder}
          deletedBy={deletedBy}
        />
      </div>
      <ConfirmModal
        id="delete-modal"
        title={t("page.user-detail.delete-modal.title")}
        description={t("page.user-detail.delete-modal.description")}
        isLoading={isSubmittingModal}
        theme="danger"
        onReject={closeModals}
        rejectBtnProps={{
          "data-testid": "delete-user-modal-cancel",
        }}
        onResolve={async () => {
          await onUserDelete();
          closeModals();
        }}
        resolveBtnProps={{
          "data-testid": "delete-user-modal-confirm",
          text: t("common.action.delete"),
        }}
        isOpen={isDeleteConfirmModalOpen}
        shouldCloseOnEsc
        data-testid="delete-user-modal"
      />
      <Modal isOpen={isDeleteDateModalOpen} shouldCloseOnEsc onRequestClose={closeModals} id="delete-date-modal">
        <article className="grid grid-cols-1 gap-y-2 px-8 py-4">
          <Headline4 className="max-w-sm justify-self-center" as="h2">
            {t("page.user-detail.delete-date-modal.title")}
          </Headline4>
          <div className="max-w-sm justify-self-center">{t("page.user-detail.delete-date-modal.description")}</div>
          <div className="flex items-center gap-2 pb-1 pt-2">
            <DateAndTimePicker value={deletionDate} onChange={setDeletionDate} min={addDays(new Date(), 1)} />
          </div>
          {userDetails.deleteAfter ? (
            <div className={twJoin("flex items-center justify-center", isSubmittingModal ? "invisible" : undefined)}>
              <Button
                styling="tertiary"
                onClick={async () => {
                  setDeletionDate("");
                  await onUserSetDeletionDate(undefined);
                  closeModals();
                }}
                icon={<XIcon className="shrink-0 text-grey-dark group-hocus:text-aop-basic-blue" size={16} />}
                iconPosition="right"
              >
                {t("page.user-detail.delete-date-modal.action.reset")}
              </Button>
            </div>
          ) : null}
          <div className="mt-4 flex flex-wrap justify-center gap-x-4 gap-y-2">
            <Button className="w-full sm:w-auto" onClick={closeModals} disabled={isSubmittingModal} styling="secondary">
              {t("common.action.cancel")}
            </Button>
            <Button
              className="w-full sm:w-auto"
              isLoading={isSubmittingModal}
              onClick={async () => {
                await onUserSetDeletionDate(deletionDate || undefined);
                closeModals();
              }}
              styling="primary"
            >
              {t("page.user-detail.delete-date-modal.action.confirm")}
            </Button>
          </div>
        </article>
      </Modal>
      <Modal
        isOpen={!isDeleteConfirmModalOpen && !isDeleteDateModalOpen && isDeleteModalOpen}
        shouldCloseOnEsc
        onRequestClose={closeModals}
        id="delete-or-date-modal"
      >
        <article className="grid grid-cols-1 gap-y-2 px-8 py-4">
          <Headline4 className="max-w-sm justify-self-center" as="h2">
            {t("page.user-detail.delete-or-date-modal.title")}
          </Headline4>
          <div className="max-w-sm justify-self-center">
            {userDetails.deleteAfter
              ? t("page.user-detail.delete-or-date-modal.description-already-set")
              : t("page.user-detail.delete-or-date-modal.description")}
          </div>
          <div className="mt-4 flex flex-wrap justify-center gap-x-4 gap-y-2">
            <Button className="w-full sm:w-auto" onClick={deleteDateHandler.setTrue} styling="secondary">
              {userDetails.deleteAfter
                ? t("page.user-detail.delete-or-date-modal.action.update-deletion-date")
                : t("page.user-detail.delete-or-date-modal.action.set-deletion-date")}
            </Button>
            <Button
              className="w-full sm:w-auto"
              onClick={deleteConfirmHandler.setTrue}
              styling="danger"
              data-testid="delete-user-modal-confirm"
            >
              {t("page.user-detail.delete-or-date-modal.action.delete")}
            </Button>
          </div>
        </article>
      </Modal>
    </DocumentPaper>
  );
}
