import type { ChatDto } from "api/types";
import refreshIcon from "assets/icons/refresh-cw-03.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Switch } from "components/Switch/Switch";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import { useConfig } from "hooks/useConfig";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { Chat } from "modules/chats/components/Chat";
import { CreateChatModal } from "modules/chats/components/CreateChatModal";
import { useCallback } from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface ChatsListLayoutProps {
  chats: ChatDto[];
  hasMoreChats: boolean | undefined;
  fetchMoreChats: () => void;
  refreshChats: () => void;
  isLoadingChats: boolean;
  isLoadingMoreChats: boolean;
  chatState: boolean | undefined;
  toggleChats: (state: boolean) => Promise<void>;
  notificationState: boolean | undefined;
  toggleChatNotification: (state: boolean) => Promise<void>;
}

export function Layout({
  chats,
  hasMoreChats,
  fetchMoreChats,
  refreshChats,
  isLoadingChats,
  isLoadingMoreChats,
  chatState,
  toggleChats,
  notificationState,
  toggleChatNotification,
}: ChatsListLayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreChats && hasMoreChats) {
        fetchMoreChats();
      }
    }, [fetchMoreChats, hasMoreChats, isLoadingMoreChats]),
  });
  const [isModalOpen, modalOpenHandler] = useBool(false);
  const hasPermission = usePermission();
  const { setting: useChatNotificationSound } = useConfig("useChatNotificationSound");

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.chats.title")}
      subTitle={t("page.chats.subtitle")}
      actions={
        <>
          <Button
            className="hidden md:block"
            data-testid="create-chat"
            title={
              !chatState || !hasPermission((x) => x.userManagement.canListUsers)
                ? t("page.chats.new-chat.disabled")
                : undefined
            }
            disabled={!chatState || !hasPermission((x) => x.userManagement.canListUsers)}
            onClick={modalOpenHandler.setTrue}
          >
            + {t("page.chats.new-chat")}
          </Button>
          <IconButton
            styling="primary"
            className="md:hidden"
            disabled={!chatState || !hasPermission((x) => x.userManagement.canListUsers)}
            title={
              !chatState || !hasPermission((x) => x.userManagement.canListUsers)
                ? t("page.chats.new-chat.disabled")
                : t("page.chats.new-chat")
            }
            onClick={modalOpenHandler.setTrue}
            isCircular
          >
            <Plus size={24} />
          </IconButton>
        </>
      }
      header={
        <div className="flex items-center justify-center gap-4 md:justify-end">
          {useChatNotificationSound && (
            <div className="flex items-center gap-2">
              <Subtitle2 className="font-normal">{t("page.chats.switch.notification")}</Subtitle2>
              <Subtitle2 className={twJoin(!notificationState ? undefined : "font-normal")}>
                {t("page.chats.switch.off")}
              </Subtitle2>
              <Switch
                onChange={() => toggleChatNotification(!notificationState)}
                isChecked={!!notificationState}
                size="md"
                toggleColour="white"
              />
              <Subtitle2 className={twJoin(notificationState ? undefined : "font-normal")}>
                {t("page.chats.switch.on")}
              </Subtitle2>
            </div>
          )}
          <div className="flex items-center gap-2">
            <Subtitle2 className="font-normal">{t("page.chats.switch.chat")}</Subtitle2>
            <Subtitle2 className={twJoin(!chatState ? undefined : "font-normal")}>
              {t("page.chats.switch.off")}
            </Subtitle2>
            <Switch onChange={() => toggleChats(!chatState)} isChecked={!!chatState} size="md" toggleColour="white" />
            <Subtitle2 className={twJoin(chatState ? undefined : "font-normal")}>{t("page.chats.switch.on")}</Subtitle2>
          </div>
          <Button styling="secondary" onClick={refreshChats}>
            <div className="flex items-center gap-1">
              <Icon name={refreshIcon} />
              {t("page.chats.refresh")}
            </div>
          </Button>
        </div>
      }
    >
      {isLoadingChats ? (
        <FullSizeLoader withPadding />
      ) : chats.length === 0 ? (
        <div className="rounded-md bg-white p-4">
          <Capture2 className="text-grey-dark">{t("page.chats.no-chats")}</Capture2>
        </div>
      ) : (
        <div className="overflow-y-auto rounded-md bg-white">
          <ul>
            {chats.map((chat, idx) => (
              <li data-testid="conversation-row" key={chat.id}>
                <Chat chatDetails={chat} index={idx} isLast={idx === chats.length - 1} />
              </li>
            ))}
          </ul>
          {hasMoreChats && (
            <div className="p-4" ref={ref}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </div>
      )}
      <CreateChatModal isOpen={isModalOpen} onClose={modalOpenHandler.setFalse} />
    </DocumentPaper>
  );
}
