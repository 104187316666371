import type {
  SelfDto,
  TicketCategoryDto,
  TicketRequest,
  UserDetailsDto,
  UserDetailsWithAddressDto,
  UserDto,
} from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormRadio } from "components/Form/FormRadio";
import { FormSearchableSelect } from "components/Form/FormSearchableSelect";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Overline2, Subtitle2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { addressTagsToDictionary } from "helpers/address";
import { validateSize } from "helpers/file-size";
import { createRequiredStringRule } from "helpers/rules";
import { useResolvedPermission } from "hooks/usePermission";
import { UserDetails } from "modules/tickets/components/UserDetails";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

enum MAX_LENGTH {
  TITLE = 50,
  DESCRIPTION = 500,
}

export interface LayoutProps {
  defaultValues: LayoutFormValues;
  isLoadingOptions: boolean;
  selectedCategory?: TicketCategoryDto;
  users: Pick<UserDetailsDto, "id" | "fullName" | "avatar" | "locatedAt" | "deletedAt">[];
  categories: TicketCategoryDto[];
  repairAssignees?: UserDto[];
  isSubmitting: boolean;
  reporter?: UserDetailsWithAddressDto;
  sessionUser: SelfDto;
  onSubmit: (data: LayoutFormValues) => void;
  onUpdateCategory: (category: TicketCategoryDto | undefined) => void;
  onUpdateReporterId?: (userId: string | undefined) => void;
}

export function Layout({
  defaultValues,
  users,
  isLoadingOptions,
  selectedCategory,
  categories,
  repairAssignees,
  reporter,
  sessionUser,
  isSubmitting,
  onSubmit,
  onUpdateCategory,
  onUpdateReporterId,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<LayoutFormValues>({
    mode: "onChange",
    defaultValues,
  });
  const canAssign = useResolvedPermission((x) => {
    if (!selectedCategory) {
      return false;
    }

    return x.hasTicketCategoryPermission(selectedCategory.id, (x) => x.canUpdateRequestStatus);
  });

  const { setValue } = formMethods;
  useEffect(() => {
    if (selectedCategory && canAssign) {
      setValue("assignee", selectedCategory.defaultAssignee);
    }
  }, [selectedCategory, setValue, canAssign]);

  const reporterId = useWatch({ name: "reporter", control: formMethods.control })?.id;

  const showVisibilitySettings =
    selectedCategory && selectedCategory.notificationType === "canBeCollective" && reporterId === sessionUser.id;

  useEffect(() => {
    if (reporterId !== sessionUser.id) {
      setValue("visibility", "private");
    }
  }, [reporterId, sessionUser.id, setValue]);

  useEffect(() => {
    onUpdateReporterId?.(defaultValues.reporter?.id);
  }, [defaultValues.reporter?.id, onUpdateReporterId]);

  useEffect(() => {
    onUpdateReporterId?.(reporterId);
  }, [reporterId, onUpdateReporterId]);

  const building = addressTagsToDictionary(reporter?.addressTags).building;

  return (
    <DocumentPaper data-testid="create-ticket" theme="no-gaps" title={t("page.tickets.create.title")}>
      <div className="flex gap-4 xl:gap-8 2xl:gap-16">
        <Form className="flex max-w-xl flex-1 flex-col gap-2" formMethods={formMethods} onSubmit={onSubmit}>
          <FormContent>
            <FormField htmlFor="categoryId" label={t("page.tickets.create.form.category")} required>
              <FormSelect<LayoutFormValues, TicketCategoryDto>
                id="categoryId"
                name="categoryId"
                placeholder={t("page.tickets.create.form.category.placeholder")}
                items={categories}
                keySelector={(x) => x.id}
                renderOption={(x) => x.name}
                onChange={onUpdateCategory}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.tickets.create.form.category"),
                  }),
                }}
              />
            </FormField>
            <FormField htmlFor="reporter" label={t("page.tickets.create.form.reporter")} required>
              <FormSearchableSelect<LayoutFormValues, LayoutProps["users"][number]>
                id="reporter"
                name="reporter"
                placeholder={t("page.tickets.create.form.reporter.placeholder")}
                items={users}
                keySelector={(x) => x.id}
                searchableFieldSelector={(x) => [x.fullName, x.locatedAt || ""]}
                renderOption={(x) => (
                  <div className="flex items-center gap-4 p-1">
                    <UserAvatar className="size-11" img={x.avatar} isUserDeleted={!!x.deletedAt} />
                    <div className="flex flex-col">
                      <Subtitle2>{x.fullName}</Subtitle2>
                      <Overline2>{x.locatedAt}</Overline2>
                    </div>
                  </div>
                )}
                renderSelected={(x) => x.fullName}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.tickets.create.form.reporter"),
                  }),
                }}
              />
            </FormField>
            {showVisibilitySettings && (
              <FormField label={t("page.tickets.create.form.visibility")} required>
                <FormErrorWrapper<LayoutFormValues> name="visibility">
                  <div className="flex flex-col items-start">
                    <FormRadio<LayoutFormValues, "visibility">
                      name="visibility"
                      value="private"
                      displayValue={t("page.tickets.create.form.visibility.private")}
                      rules={{
                        required: t("components.form.error.required", {
                          inputName: t("page.tickets.create.form.visibility"),
                        }),
                      }}
                    />
                    <FormRadio<LayoutFormValues, "visibility">
                      name="visibility"
                      value="sharedWithBuilding"
                      displayValue={
                        building
                          ? t("page.tickets.create.form.visibility.shared-with-resident-building", {
                              building,
                            })
                          : t("page.tickets.create.form.visibility.shared-with-building")
                      }
                      rules={{
                        required: t("components.form.error.required", {
                          inputName: t("page.tickets.create.form.visibility"),
                        }),
                      }}
                    />
                    <FormRadio<LayoutFormValues, "visibility">
                      name="visibility"
                      value="sharedWithCommunity"
                      displayValue={t("page.tickets.create.form.visibility.shared-with-community")}
                      rules={{
                        required: t("components.form.error.required", {
                          inputName: t("page.tickets.create.form.visibility"),
                        }),
                      }}
                    />
                  </div>
                </FormErrorWrapper>
              </FormField>
            )}

            <FormField htmlFor="title" label={t("page.tickets.create.form.title")} required>
              <FormInput<LayoutFormValues>
                id="title"
                name="title"
                placeholder={t("page.tickets.create.form.title.placeholder")}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.tickets.create.form.title"),
                  },
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_LENGTH.TITLE }),
                    value: MAX_LENGTH.TITLE,
                  },
                }}
              />
            </FormField>
            <FormField htmlFor="description" label={t("page.tickets.create.form.description")} required>
              <FormTextArea<LayoutFormValues>
                id="description"
                name="description"
                placeholder={t("page.tickets.create.form.description.placeholder")}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.tickets.create.form.description"),
                  },
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_LENGTH.DESCRIPTION }),
                    value: MAX_LENGTH.DESCRIPTION,
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.tickets.create.form.multi.image")} htmlFor="image">
              <FormImageInput<LayoutFormValues, "images">
                name="images"
                id="images"
                nOfImages={5}
                rules={{
                  validate: {
                    size(images) {
                      if (images) {
                        return validateSize(t, images);
                      }
                    },
                  },
                }}
              />
            </FormField>
            {canAssign && (
              <FormField htmlFor="assigneeId" label={t("page.tickets.create.form.assignee")}>
                <FormSelect<LayoutFormValues, UserDto>
                  id="assignee"
                  name="assignee"
                  items={repairAssignees || []}
                  emptyItem={t("page.tickets.create.form.assignee.unassigned")}
                  keySelector={(x) => x.id}
                  renderOption={(x) => x.fullName}
                  disabled={isLoadingOptions}
                />
              </FormField>
            )}
          </FormContent>
          <Button type="submit" isLoading={isSubmitting}>
            {t("page.tickets.create.submit")}
          </Button>
        </Form>
        <div className="hidden lg:flex">{reporter ? <UserDetails user={reporter} /> : null}</div>
      </div>
    </DocumentPaper>
  );
}

export interface LayoutFormValues {
  categoryId?: string;
  reporter?: UserDetailsDto;
  assignee?: UserDto;
  title: string;
  description: string;
  images?: FormImage[];
  visibility: TicketRequest["visibility"];
}
