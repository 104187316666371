import { Headline4, Subtitle2 } from "components/Text/Text";
import type React from "react";

type ServiceDetailsBlockCardProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
} & (
  | {
      backgroundImage: string;
      thumbnailImage?: string | undefined;
    }
  | {
      backgroundImage?: never;
      thumbnailImage?: never;
    }
);

export function ServiceDetailsBlockCard({
  title,
  subtitle,
  backgroundImage,
  thumbnailImage,
  children,
}: ServiceDetailsBlockCardProps): React.ReactNode {
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg bg-white shadow-md">
      {backgroundImage && (
        <div className="relative w-full">
          {backgroundImage && (
            <div
              className="h-64 w-full rounded-t-lg bg-grey-light bg-cover bg-center p-2"
              style={{
                backgroundImage: `url(${backgroundImage})`,
              }}
            />
          )}
          {thumbnailImage && (
            <img
              className="absolute right-8 size-24 -translate-y-1/2 rounded-full border-4 border-white bg-white object-cover"
              src={thumbnailImage}
              alt={title}
            />
          )}
        </div>
      )}
      <div className="flex flex-col gap-2 p-4">
        {title && <Headline4 className="mb-2">{title}</Headline4>}
        {subtitle && <Subtitle2>{subtitle}</Subtitle2>}
        {children}
      </div>
    </div>
  );
}
