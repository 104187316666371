import type { ImageDto } from "api/types";
import iconUsersCheck from "assets/icons/users-check.svg";
import { Button } from "components/Button/Button";
import { Card, CardActions, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import { type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { Subtitle2 } from "components/Text/Text";
import { isDefined } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { Specification } from "modules/bookings/components/Specification";
import { canViewSchedule } from "modules/bookings/permissions";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

interface BookableAssetCardProps {
  "data-testid"?: string;
  id: string;
  actions: ContextMenuAction[];
  images: ImageDto[];
  name: string;
  description: string;
  sizeSpecification?: string;
  pricePerHourSpecification?: number;
  capacitySpecification?: number;
  locationSpecification?: string;
  publishAt?: string;
  unpublishAt?: string;
  isAudienceSet?: boolean;
}

export function BookableAssetCard({
  "data-testid": dataTestId,
  actions,
  id,
  images,
  name,
  description,
  sizeSpecification,
  pricePerHourSpecification,
  capacitySpecification,
  locationSpecification,
  publishAt,
  unpublishAt,
  isAudienceSet,
}: BookableAssetCardProps): React.ReactNode {
  const slug = useSlug();
  const { i18n, t } = useTranslation();
  const sessionUser = useSessionUser();

  const assetDetailPath = routes.bookings.assetDetail({ slug, id: id });
  const bookAssetPath = routes.bookings.bookAsset({ slug, aid: id });

  const userCanViewCalendar = useResolvedPermission(canViewSchedule);

  const publishDate = publishAt ? new Date(publishAt) : undefined;
  const unpublishDate = unpublishAt ? new Date(unpublishAt) : undefined;

  return (
    <Card data-testid={dataTestId} link={userCanViewCalendar ? assetDetailPath : bookAssetPath}>
      <CardImage
        image={images[0]}
        labels={[
          publishDate && publishDate > new Date() && (
            <Label theme="blue">
              {t("page.bookings.list-assets.asset.publish-date", {
                date: formatDate(i18n, "datetimeShort", publishDate),
              })}
            </Label>
          ),
          unpublishDate && (
            <Label theme="blue">
              {unpublishDate > new Date()
                ? t("page.bookings.list-assets.asset.unpublish-date", {
                    date: formatDate(i18n, "datetimeShort", unpublishDate),
                  })
                : t("page.bookings.list-assets.asset.unpublished")}
            </Label>
          ),
          isAudienceSet && sessionUser.isAdmin && (
            <Label theme="blue">
              <Icon size={16} name={iconUsersCheck} />
            </Label>
          ),
        ].filter(isDefined)}
      />

      <CardContent>
        <CardHeader
          title={name}
          titleLink={userCanViewCalendar ? assetDetailPath : bookAssetPath}
          menuActions={actions}
        />

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Specification specification="sizeSpecification" value={sizeSpecification} />
            <Specification specification="pricePerHourSpecification" value={pricePerHourSpecification} />
            <Specification specification="capacitySpecification" value={capacitySpecification} />
          </div>
          <Specification specification="locationSpecification" value={locationSpecification} />
        </div>

        <CardDescription description={description} />

        <CardActions>
          <Button type="link" data-testid="book-asset-btn" href={bookAssetPath} styling="primary">
            <Subtitle2>{t("page.bookings.list-assets.asset.book")}</Subtitle2>
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
