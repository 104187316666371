import { Dialog as ArkDialog, Portal as ArkPortal } from "@ark-ui/react";
import xIcon from "assets/icons/x.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import type React from "react";
import { useTranslation } from "react-i18next";

export interface DrawerRootProps {
  title: string;
  triggerComponent: React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
}

function DrawerRoot({ title, triggerComponent, children, onClose }: DrawerRootProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <ArkDialog.Root lazyMount unmountOnExit>
      <ArkDialog.Trigger asChild>{triggerComponent}</ArkDialog.Trigger>
      <ArkPortal>
        <ArkDialog.Backdrop className="fixed left-0 top-0 z-40 size-full cursor-pointer bg-grey-darkest/60 data-[state=closed]:animate-fade-out data-[state=open]:animate-fade-in" />
        <ArkDialog.Positioner className="fixed bottom-0 right-0 z-50 h-fit w-full md:top-0 md:h-full md:w-fit">
          <ArkDialog.Content className="h-[90svh] w-full rounded-t-lg bg-white data-[state=closed]:animate-slide-out-y data-[state=open]:animate-slide-in-y md:h-full md:w-[456px] md:rounded-l-lg md:rounded-tr-none md:data-[state=closed]:animate-slide-out-x md:data-[state=open]:animate-slide-in-x">
            <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-lighter p-6">
              <ArkDialog.Title asChild>
                <h3 className="text-headline3 font-bold leading-none">{title}</h3>
              </ArkDialog.Title>
              <ArkDialog.CloseTrigger asChild>
                <IconButton
                  title={t("common.action.close")}
                  onClick={onClose}
                  size="sm"
                  styling="ghostSecondary"
                  withTooltip={false}
                >
                  <Icon name={xIcon} size={24} />
                </IconButton>
              </ArkDialog.CloseTrigger>
            </div>
            {children}
          </ArkDialog.Content>
        </ArkDialog.Positioner>
      </ArkPortal>
    </ArkDialog.Root>
  );
}

interface DrawerBodyProps {
  children: React.ReactNode;
}

function DrawerBody({ children }: DrawerBodyProps): React.ReactNode {
  return <div className="flex size-full flex-col p-6">{children}</div>;
}

interface DrawerCloseTriggerProps {
  children: React.ReactNode;
}

function DrawerCloseTrigger({ children }: DrawerCloseTriggerProps): React.ReactNode {
  return <ArkDialog.CloseTrigger asChild>{children}</ArkDialog.CloseTrigger>;
}

export const Drawer = {
  Root: DrawerRoot,
  Body: DrawerBody,
  CloseTrigger: DrawerCloseTrigger,
};
