import { Button } from "components/Button/Button";
import { Capture2, Subtitle1 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import type { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Smartphone, X as XIcon } from "react-feather";
import { twJoin } from "tailwind-merge";

export interface PreviewLayoutProps {
  preview: React.ReactNode;
  showPreviewButtonText: string;
  previewTitleText: string;
  previewWarning?: string;
}
export function PreviewLayout(props: PropsWithChildren<PreviewLayoutProps>): React.ReactNode {
  const [isPreviewOpen, previewOpenHandlers] = useBool();
  useKey("Escape", previewOpenHandlers.setFalse, isPreviewOpen);

  const isXl = useScreenIsBiggerThan("xl");

  const previewSection = (
    <section
      className={twJoin(
        "fixed inset-0 z-30 flex-1 items-center justify-center bg-grey-darkest/60 lg:static lg:flex-auto lg:flex-col lg:justify-start lg:bg-transparent",
        isPreviewOpen ? "flex" : "hidden lg:flex",
      )}
      onClick={previewOpenHandlers.setFalse}
    >
      <div className="flex flex-col items-center justify-center lg:sticky lg:top-6">
        <Subtitle1 className="sr-only lg:not-sr-only lg:text-center lg:uppercase lg:text-grey-light" as="h2">
          {props.previewTitleText}
        </Subtitle1>
        <div className="relative w-[70vw] max-w-72 lg:mt-4">
          <Button
            styling="tertiary"
            className="absolute right-0 top-0 -translate-y-full translate-x-full text-white lg:hidden"
            onClick={previewOpenHandlers.setFalse}
          >
            <XIcon className="w-6" />
          </Button>
          {props.preview}
        </div>
        <Capture2 className="hidden w-[350px] pt-4 text-grey-dark lg:block">{props.previewWarning}</Capture2>
      </div>
    </section>
  );

  return (
    <div className="flex gap-8">
      <section className="flex w-full flex-col sm:block sm:w-auto xl:min-w-[500px] 2xl:min-w-[700px]">
        <div className="sticky top-0 z-10 overflow-hidden bg-white">
          <Button
            styling="tertiary"
            className="grid grid-cols-[1fr_auto] gap-x-1 py-3 lg:hidden"
            onClick={previewOpenHandlers.setTrue}
          >
            <Smartphone />
            {props.showPreviewButtonText}
          </Button>
        </div>
        {props.children}
      </section>
      {!isXl && isPreviewOpen ? createPortal(previewSection, document.body) : previewSection}
    </div>
  );
}
