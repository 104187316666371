import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ChatReplyDto, ChatReplyRequest } from "api/types";
import refreshIcon from "assets/icons/refresh-cw-03.svg";
import users01Icon from "assets/icons/users-01.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { useSignalRHub, useSignalRInvocation, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { ChatWindow } from "modules/chats/components/ChatWindow";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { routes } from "routes";

import { EditGroupChatModal } from "./EditGroupChatModal";

const CHATS_PAGE = 10;

export function ChatDetailPage(): React.ReactNode {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const slug = useSlug();
  const api = useApi();
  const query = useQueryClient();
  const { uploadFormImage } = useUploadImage();
  const showFlashToast = useFlashToast();
  const { id: chatId } = useParams<{ id: string }>();
  const [chatReplies, setChatReplies] = useState<ChatReplyDto[]>([]);
  const [isEditGroupModalOpen, editGroupModalHandler] = useBool(false);

  const { signalRConnection } = useSignalRHub("chat-hub", {
    query: `userId=${sessionUser.id}&chatId=${chatId}`,
  });
  const { invoke: onReadMessage } = useSignalRInvocation<never>(signalRConnection, "ChatMessageRead");

  const onNewChatMessage = useCallback(
    async (...args: any[]) => {
      const latestChatReply = args[0].latestReply as ChatReplyDto;

      setChatReplies((prev) => [...prev, latestChatReply]);
      await onReadMessage({ chatId: chatId, userId: sessionUser.id });
    },
    [setChatReplies, onReadMessage, chatId, sessionUser.id],
  );

  useSignalRSubscription(signalRConnection, "NewChatMessage", onNewChatMessage);

  const {
    data: chatDetails,
    isPending: isLoadingChatDetails,
    error: errorLoadingChatDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.CHATS_DETAILS(projectId, chatId!),
    queryFn: () => api.getChatsDetailsV2(chatId!),
    select: commonAPIDataSelector,
  });
  const replyChat = useMutation({
    mutationFn: ({ payload }: { payload: ChatReplyRequest }) =>
      api.postChatsReplyV2(chatId!, payload).then((x) => x.data),
    onSuccess: async () => {
      await query.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatId!) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.chat-detail.chat.send-error") });
    },
  });
  const {
    data: repliesData,
    hasNextPage: hasMoreReplies,
    fetchNextPage: fetchMoreReplies,
    refetch: refreshReplies,
    isPending: isLoadingReplies,
    isFetchingNextPage: isLoadingMoreReplies,
    error: errorLoadingReplies,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatId!),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsRepliesV2(chatId!, { Offset: pageParam * CHATS_PAGE, Limit: CHATS_PAGE })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    getPreviousPageParam: () => {
      return 0;
    },
  });

  useEffect(() => {
    if (repliesData?.pages.some((x) => x.items.some((y) => y.isUnread))) {
      void query.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_STATUS(projectId) });
    }
  }, [repliesData?.pages, projectId, query]);

  useEffect(() => {
    setChatReplies(repliesData?.pages.flatMap((x) => x.items) ?? []);
  }, [repliesData]);

  const sendChat = async ({ message, files }: { message: string; files: FormImage[] }) => {
    const uploadedImage = await uploadFormImage(files[0]);

    return await replyChat.mutateAsync({
      payload: { text: message, imageId: uploadedImage?.id },
    });
  };

  if (isLoadingChatDetails || isLoadingReplies) {
    return <FullSizeLoader />;
  }

  const error = !chatId || errorLoadingChatDetails || errorLoadingReplies;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={
        chatDetails.chatType === "single"
          ? chatDetails.privateChatInfo!.receiver.fullName
          : chatDetails.groupChatInfo!.name
      }
      renderTitle={(title?: string) => {
        return (
          <div className="flex w-full items-center gap-2 overflow-hidden md:gap-4">
            {chatDetails.chatType === "single" && <UserAvatar img={chatDetails.privateChatInfo!.receiver.avatar} />}
            {chatDetails.chatType === "group" && (
              <div className="flex size-10 items-center justify-center rounded-full border border-grey-lightest text-grey-light">
                {chatDetails.groupChatInfo?.image ? (
                  <UserAvatar img={chatDetails.groupChatInfo.image} />
                ) : (
                  <Icon name={users01Icon} size={24} />
                )}
              </div>
            )}
            <span
              className="flex-1 truncate hover:cursor-pointer hover:text-aop-basic-blue"
              onClick={editGroupModalHandler.setTrue}
            >
              {title}
            </span>
          </div>
        );
      }}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.chat-detail.breacrumbs.chats"),
              to: routes.chats.list({ slug }),
            },
            {
              name: t("page.chat-detail.breacrumbs.chat-detail"),
            },
          ]}
        />
      }
      actions={
        <Button styling="secondary" onClick={() => void refreshReplies()}>
          <div className="flex items-center gap-1">
            <Icon name={refreshIcon} />
            {t("page.chats.refresh")}
          </div>
        </Button>
      }
    >
      <ChatWindow
        canChat={chatDetails?.chatType === "single" ? chatDetails.privateChatInfo!.receiver.chatEnabled : true}
        chatReplies={chatReplies}
        hasMoreReplies={hasMoreReplies}
        fetchMoreReplies={fetchMoreReplies}
        isLoadingMoreReplies={isLoadingMoreReplies}
        sendChat={sendChat}
        chatName={chatDetails?.groupChatInfo?.name}
      />
      <EditGroupChatModal
        isOpen={isEditGroupModalOpen}
        onClose={editGroupModalHandler.setFalse}
        chatId={chatId}
        groupInfo={chatDetails.groupChatInfo!}
      />
    </DocumentPaper>
  );
}
