import { Tag } from "components/Tag/Tag";
import { Tooltip } from "components/Tooltip/Tooltip";
import type { UseComboboxPropGetters } from "downshift";
import { useCombobox, useMultipleSelection } from "downshift";
import { twResolve } from "helpers/tw-resolve";
import type { ReactNode } from "react";
import { memo, useMemo, useState } from "react";
import { Check as CheckIcon, ChevronDown, ChevronUp, X as XIcon } from "react-feather";

export interface MultiSelectProps<T> {
  placeholder?: string;
  selected: T[] | undefined;
  disabled?: boolean;
  items: T[];
  renderOption: (item: T, selected: boolean) => ReactNode;
  keySelector: (item: T) => string | number;
  onChange?: (items: T[]) => void;
  onBlur?: () => void;
  disabledItems?: T[];
  disabledItemTooltip?: string;
  searchField?: (item: T) => string;
  sortSelectedItems?: (a: T, b: T) => number;
  "aria-invalid"?: boolean;
  id?: string;
}

export function MultiSelect<T>({
  selected = [],
  disabled,
  placeholder,
  items,
  renderOption,
  keySelector,
  onChange,
  onBlur,
  disabledItems = [],
  disabledItemTooltip,
  searchField,
  sortSelectedItems,
  ...props
}: MultiSelectProps<T>): React.ReactNode {
  const [inputValue, setInputValue] = useState("");
  const {
    getSelectedItemProps,
    getDropdownProps,
    setSelectedItems,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    selectedItems: selected,
    defaultActiveIndex: 0,
    onSelectedItemsChange: (stateChange) => {
      onChange?.(stateChange.selectedItems ?? []);
      // Close menu if all items are selected
      if (stateChange.selectedItems.length === items.length) {
        closeMenu();
      }
    },
    onStateChange: ({ type, selectedItems: newSelectedItems = [] }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          setSelectedItems(newSelectedItems);
          break;
        default:
          break;
      }
    },
  });
  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        if (selectedItems.some((selectedItem) => keySelector(selectedItem) === keySelector(item))) {
          return false;
        }

        if (searchField) {
          return searchField(item).toLowerCase().includes(inputValue.toLowerCase());
        }

        // eslint-disable-next-line testing-library/render-result-naming-convention
        const renderedResult = renderOption(item, false);
        if (typeof renderedResult === "string") {
          return renderedResult.toLowerCase().includes(inputValue.toLowerCase());
        }

        return false;
      }),
    [inputValue, items, keySelector, searchField, renderOption, selectedItems],
  );
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getLabelProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useCombobox({
    inputValue,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: filteredItems,
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            ...(changes.selectedItem
              ? {
                  isOpen: true,
                  highlightedIndex:
                    filteredItems.length <= state.highlightedIndex + 1
                      ? filteredItems.length - 2
                      : state.highlightedIndex,
                }
              : null),
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || "");
          break;
        case useCombobox.stateChangeTypes.InputBlur:
          setInputValue("");
          onBlur?.();
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem && !disabledItems.includes(selectedItem)) {
            setInputValue("");
            if (selectedItems.includes(selectedItem)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }
          }
          break;
        default:
          break;
      }
    },
  });

  const sortedSelectedItems = useMemo(() => {
    if (sortSelectedItems) {
      return selectedItems.sort(sortSelectedItems);
    }

    return selectedItems;
  }, [selectedItems, sortSelectedItems]);

  return (
    <div
      className={twResolve("relative", isOpen && "z-10", disabled && "pointer-events-none opacity-50")}
      data-testid="multi-select"
    >
      <div>
        <div
          {...getLabelProps()}
          className={twResolve(
            "relative flex max-h-48 min-h-[2.25rem] w-full cursor-default flex-wrap items-center overflow-y-auto rounded-lg border border-grey-lighter bg-white pl-3 pr-10 text-left leading-[26px] hocus:border-grey-darker hocus:outline-none",
            isOpen && "rounded-b-none border-grey-darker hocus:border-grey-darker",
            props["aria-invalid"] ? "ring-2 ring-red" : undefined,
          )}
        >
          {sortedSelectedItems.map((selectedItem, index) => (
            <button
              key={`selected-${keySelector(selectedItem)}`}
              {...getSelectedItemProps({ selectedItem, index })}
              type="button"
              className="my-1 mr-3 cursor-pointer focus:outline-none"
              onClick={() => removeSelectedItem(selectedItem)}
            >
              <Tag clickable>
                {renderOption(selectedItem, true)}
                <span className="flex h-full items-center pl-2 text-grey-darker focus:outline-none">
                  <XIcon className="size-3" />
                </span>
              </Tag>
            </button>
          ))}
          <input
            {...getInputProps(
              getDropdownProps({
                preventKeyAction: isOpen,
              }),
            )}
            placeholder={!selectedItems.length ? placeholder : ""}
            className="h-10 w-0 min-w-[30px] flex-1 py-1 text-base focus:outline-none"
          />
          <button
            {...getToggleButtonProps({ type: "button" })}
            id={props.id}
            className="absolute right-0 top-0 flex h-full w-10 items-center justify-center focus:outline-none"
            aria-label="toggle menu"
            data-testid="multi-select-toggle"
          >
            <span className="flex items-center text-grey-darker">
              {isOpen ? <ChevronUp className="w-4" /> : <ChevronDown className="w-4" />}
            </span>
          </button>
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className={twResolve(
          isOpen ? "opacity-100" : "opacity-0",
          "absolute flex max-h-96 min-h-[2.25rem] w-full flex-col items-start overflow-auto rounded-lg rounded-t-none border border-t-0 border-grey-darker bg-white shadow-md hocus:outline-none",
        )}
      >
        {isOpen &&
          filteredItems.map((item, index) => (
            <ListItem<T>
              key={keySelector(item)}
              renderOption={renderOption}
              selectedItems={selectedItems}
              item={item}
              index={index}
              isHighlighted={highlightedIndex === index}
              getItemProps={getItemProps}
              disabled={disabledItems.includes(item)}
              disabledTooltip={disabledItemTooltip}
            />
          ))}
      </ul>
    </div>
  );
}

const ListItem = memo(ListItemInner) as typeof ListItemInner;

function ListItemInner<T>(props: {
  item: T;
  index: number;
  isHighlighted: boolean;
  getItemProps: UseComboboxPropGetters<T>["getItemProps"];
  renderOption: MultiSelectProps<T>["renderOption"];
  selectedItems: T[];
  disabled: boolean;
  disabledTooltip?: string;
}) {
  const { item, index, isHighlighted, getItemProps, renderOption, selectedItems, disabled, disabledTooltip } = props;

  const isSelected = selectedItems.includes(item);

  return (
    <li
      data-testid="multi-select-item"
      {...getItemProps({ index, item })}
      className={twResolve(
        "w-full cursor-pointer select-none px-3 py-1 text-black first:mt-2 last:mb-2",
        isHighlighted ? "bg-blue-lightest" : undefined,
        disabled && "cursor-default text-grey-light",
      )}
    >
      <Tooltip tooltip={disabled && disabledTooltip}>
        <div className={twResolve("flex items-center justify-between gap-2", isSelected ? "font-semibold" : undefined)}>
          {renderOption(item, selectedItems.includes(item))}
          {isSelected ? <CheckIcon className="w-4 text-aop-basic-blue" /> : null}
        </div>
      </Tooltip>
    </li>
  );
}
