import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ConsumptionResultDto, ServicePartnerPageDetailsDto } from "api/types";
import watchSquareIcon from "assets/icons/watch-square.svg";
import { Button } from "components/Button/Button";
import { DateRange } from "components/DateRange/DateRange";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { GrowLabel } from "components/GrowLabel/GrowLabel";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { endOfMonth, format, isSameMonth, startOfMonth } from "date-fns";
import { SUPPORT_EMAIL } from "helpers/constants";
import { asUtc } from "helpers/date";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import electricityIcon from "../../assets/iqbi-electricity.png";
import heatingIcon from "../../assets/iqbi-heating.png";
import solarIcon from "../../assets/iqbi-solar.png";
import waterIcon from "../../assets/iqbi-water.png";
import { ServiceDetailsBlockCard } from "../ServiceDetails/ServiceDetailsBlockCard";
import { IqbiDetailsConsentModal } from "./IqbiDetailsConsentModal";
import { IqbiDetailsVerifyAddressModal } from "./IqbiDetailsVerifyAddressModal";

interface IqbiDetailsConsumptionCardProps {
  serviceDetails: ServicePartnerPageDetailsDto;
}

const DEFAULT_IQBI_CONSUMPTION: ConsumptionResultDto[] = [
  {
    requestedMonth: {
      value: 0,
    },
    unitType: "electricity",
    percentageChange: 0,
    previousMonth: {
      value: 0,
    },
    usageChange: 0,
  },
  {
    requestedMonth: {
      value: 0,
    },
    unitType: "gas",
    percentageChange: 0,
    previousMonth: {
      value: 0,
    },
    usageChange: 0,
  },
  {
    requestedMonth: {
      value: 0,
    },
    unitType: "water",
    percentageChange: 0,
    previousMonth: {
      value: 0,
    },
    usageChange: 0,
  },
];

export function IqbiDetailsConsumptionCard({ serviceDetails }: IqbiDetailsConsumptionCardProps): React.ReactNode {
  const [isVerifyAddressModalOpen, verifyAddressModalHandlers] = useBool(false);
  const [isConsentModalOpen, consentModalHandlers] = useBool(false);
  const [queryDate, setQueryDate] = useState<Date>(startOfMonth(new Date()));
  const projectId = useProjectId();
  const api = useApi();
  const { t } = useTranslation();
  const month = format(asUtc(queryDate), "yyyy-MM-dd");
  const {
    data: iqbiConsumption,
    isFetching: isFetchingIqbiConsumption,
    error: iqbiConsumptionError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNER_IQBI(projectId, serviceDetails.id, month),
    queryFn: () => api.getServicepartnersIqbiConsumptionV1({ month }),
    placeholderData: keepPreviousData,
    enabled: serviceDetails.partnerProperties.iqbi?.status === "ready",
    staleTime: Infinity,
  });

  const iqbiStatus = serviceDetails.partnerProperties.iqbi?.status;

  return (
    <>
      <ServiceDetailsBlockCard title={t("page.service-details.iqbi-consumption.title")}>
        <div className="relative w-full">
          {iqbiStatus === "noConsent" && (
            <>
              <IqbiDetailsConsumptionCardOverview consumptions={DEFAULT_IQBI_CONSUMPTION} date={queryDate} />
              <div className="absolute inset-0 flex size-full items-center justify-center bg-white/60 backdrop-blur-sm">
                <Button onClick={consentModalHandlers.setTrue}>
                  {t("page.service-details.iqbi.give-consent-btn")}
                </Button>
              </div>
            </>
          )}

          {iqbiStatus === "notVerified" && (
            <>
              <IqbiDetailsConsumptionCardOverview consumptions={DEFAULT_IQBI_CONSUMPTION} date={queryDate} />
              <div className="absolute inset-0 flex size-full items-center justify-center bg-white/60 backdrop-blur-sm">
                <Button onClick={verifyAddressModalHandlers.setTrue}>
                  {t("page.service-details.iqbi.verify-btn")}
                </Button>
              </div>
            </>
          )}

          {iqbiStatus === "waitingForConsentApproval" && (
            <>
              <IqbiDetailsConsumptionCardOverview consumptions={DEFAULT_IQBI_CONSUMPTION} date={queryDate} />
              <div className="absolute inset-0 flex size-full items-center justify-center bg-white/60 backdrop-blur-sm">
                <div className="mx-2 flex w-full max-w-[320px] items-start gap-2 rounded-lg border border-blue-dark bg-blue-lightest p-4 md:mx-4">
                  <Icon name={watchSquareIcon} size={24} className="text-blue-dark" />
                  <p className="text-capture1 text-blue-dark">
                    <Trans
                      i18nKey="page.service-details.iqbi.waiting-for-consent-approval.description"
                      components={{
                        email: (
                          <a className="text-aop-basic-blue" href={`mailto:${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </>
          )}

          {iqbiStatus === "contactSupport" && (
            <>
              <IqbiDetailsConsumptionCardOverview consumptions={DEFAULT_IQBI_CONSUMPTION} date={queryDate} />
              <div className="absolute inset-0 flex size-full items-center justify-center bg-white/60 backdrop-blur-sm">
                <div className="mx-2 w-full max-w-[320px] rounded-lg border border-blue-dark bg-blue-lightest p-4 md:mx-4">
                  <p className="text-capture1 text-blue-dark">
                    <Trans
                      i18nKey="page.service-details.iqbi.contact-support.description"
                      components={{
                        email: (
                          <a className="text-aop-basic-blue" href={`mailto:${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </>
          )}

          {iqbiStatus === "ready" && (
            <>
              {/* Loading state */}
              {isFetchingIqbiConsumption && <LoadingIcon className="absolute inset-0 m-auto size-8" />}
              {/* Empty or error state */}
              {((!isFetchingIqbiConsumption && !iqbiConsumption) || iqbiConsumptionError) && (
                <p className="my-2 text-grey-darker">{t("page.service-details.iqbi-consumption.no-data")}</p>
              )}
              {/* Success state */}
              {!isFetchingIqbiConsumption && iqbiConsumption && (
                <>
                  <MonthPicker onChange={setQueryDate} placement="left" value={queryDate} maxDate={new Date()} />
                  <IqbiDetailsConsumptionCardOverview consumptions={iqbiConsumption.data} date={queryDate} />
                </>
              )}
            </>
          )}
        </div>
      </ServiceDetailsBlockCard>

      {/* Modals */}
      <IqbiDetailsVerifyAddressModal
        isOpen={isVerifyAddressModalOpen}
        onClose={verifyAddressModalHandlers.setFalse}
        serviceId={serviceDetails.id}
      />
      <IqbiDetailsConsentModal
        isOpen={isConsentModalOpen}
        onClose={consentModalHandlers.setFalse}
        serviceId={serviceDetails.id}
      />
    </>
  );
}

interface IqbiDetailsConsumptionCardOverviewProps {
  consumptions: ConsumptionResultDto[];
  date: Date;
}

export function IqbiDetailsConsumptionCardOverview({
  consumptions,
  date,
}: IqbiDetailsConsumptionCardOverviewProps): React.ReactNode {
  const { t, i18n } = useTranslation();

  const getConsumptionIcon = (unitType: ConsumptionResultDto["unitType"]) => {
    switch (unitType) {
      case "electricity":
        return electricityIcon;
      case "gas":
        return heatingIcon;
      case "water":
        return waterIcon;
      default:
        return solarIcon;
    }
  };

  const getConsumptionUnit = (unitType: ConsumptionResultDto["unitType"]) => {
    switch (unitType) {
      case "electricity":
        return "kWh";
      case "gas":
      case "water":
      default:
        return "m3";
    }
  };

  return (
    <div className="flex w-full flex-col items-start">
      <div className="flex w-full grid-cols-2 flex-col gap-8 gap-x-20 py-6 lg:grid">
        {consumptions.map((consumption) => {
          const icon = getConsumptionIcon(consumption.unitType);
          const unit = getConsumptionUnit(consumption.unitType);

          return (
            <div className="flex items-center justify-between gap-2" key={consumption.unitType}>
              <div className="flex items-center gap-4">
                <img src={icon} width={40} height={40} alt="" />
                <p className="flex items-baseline gap-1">
                  <span className="text-3xl font-semibold">{consumption.requestedMonth.value}</span>
                  <span>{unit}</span>
                </p>
              </div>

              <div>
                <GrowLabel
                  type="percentage"
                  percentage={consumption.percentageChange || 0}
                  showZero={consumption.percentageChange != null}
                  isWide
                  increasingIsBad
                />
              </div>
            </div>
          );
        })}
      </div>
      <p className="text-grey-dark">
        {isSameMonth(date, new Date()) ? (
          <span>* {t("page.service-details.date.until", { date: formatDate(i18n, "date", new Date()) })}</span>
        ) : (
          <span>
            * <DateRange start={startOfMonth(date)} end={endOfMonth(date)} format="noTime" className="inline" />
          </span>
        )}
      </p>
    </div>
  );
}
