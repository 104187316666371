import { type HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useSecurityData } from "authentication/SecurityDataContext";
import { useCallback, useEffect, useState } from "react";

import { useConfig } from "./useConfig";

export type SignalRHub =
  | "community-feed-hub"
  | "community-feed-detail-hub"
  | "notification-hub"
  | "chat-hub"
  | "broadcast"
  | "test";

export function useSignalRHub(
  hub: SignalRHub,
  opts?: {
    query?: string;
    disabled?: boolean;
  },
): {
  signalRConnection: HubConnection | null;
} {
  const securityData = useSecurityData();
  const { setting: useSignalR } = useConfig("useSignalR");
  const { setting: coreApiUri } = useConfig("newCoreApiRootUri");
  const [signalRConnection, setSignalRConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    if (!useSignalR) {
      console.debug("SignalR is disabled");

      return;
    }

    if (opts?.disabled) {
      return;
    }

    const hubConnectionConfig = new HubConnectionBuilder();

    const url = `${coreApiUri}/hubs/${hub}${opts?.query ? "?" + opts.query : ""}`;
    hubConnectionConfig
      .withUrl(url, {
        accessTokenFactory: async () => {
          if (!securityData || !securityData.getToken) {
            throw new Error("Security data is not available");
          }

          const token = await securityData.getToken();

          if (!token) {
            throw new Error("Token is not available");
          }

          return token;
        },
        withCredentials: false,
      })
      .withAutomaticReconnect();

    const hubConnection = hubConnectionConfig.build();

    void hubConnection
      .start()
      .then(() => {
        console.debug(`SignalR connection established with ${hub} - ${hubConnection.connectionId}`);
        setSignalRConnection(hubConnection);
      })
      .catch((e) => {
        console.debug(`SignalR connection failed with ${hub}`);
        console.debug(e);
      });

    return () => {
      if (
        hubConnection.state === HubConnectionState.Reconnecting ||
        hubConnection.state === HubConnectionState.Connecting ||
        hubConnection.state === HubConnectionState.Connected
      ) {
        void hubConnection.stop();
      }

      setSignalRConnection(null);

      console.debug(`SignalR connection closed with ${hub}`);
    };
  }, [useSignalR, coreApiUri, hub, opts?.disabled, opts?.query, securityData]);

  return { signalRConnection: signalRConnection };
}

export function useSignalRSubscription(
  signalRHub: HubConnection | null,
  methodName: string,
  method: (...args: any[]) => void,
): void {
  useEffect(() => {
    if (!signalRHub || signalRHub.state !== HubConnectionState.Connected) {
      console.debug("SignalR connection is not established");

      return;
    }

    signalRHub.on(methodName, method);
    console.debug(`Subscribed to ${methodName}`);

    return () => {
      signalRHub.off(methodName, method);
      console.debug(`Unsubscribed from ${methodName}`);
    };
  }, [signalRHub, method, methodName]);
}

export function useSignalRInvocation<T>(
  signalRHub: HubConnection | null,
  methodName: string,
): { invoke: (...args: any[]) => Promise<T | undefined> } {
  const invoke = useCallback(
    async (...args: any[]) => {
      try {
        if (!signalRHub || signalRHub.state !== HubConnectionState.Connected) {
          throw new Error("SignalR connection is not established");
        }

        const data = await signalRHub.invoke<T>(methodName, ...args);

        return data;
      } catch (e) {
        console.debug(e);
      }
    },
    [signalRHub, methodName],
  );

  return { invoke };
}
