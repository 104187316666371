import { Button } from "components/Button/Button";
import { CardGrid } from "components/CardGrid/CardGrid";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { isDefined } from "helpers/util";
import { useConfig } from "hooks/useConfig";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { ServiceCard } from "modules/service-partners/components/Card/ServiceCard";
import type { ServicePartnersListProps } from "modules/service-partners/components/List/ServicePartnersList";
import { ServicePartnersList } from "modules/service-partners/components/List/ServicePartnersList";
import { canCreateNew } from "modules/service-partners/permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps extends Omit<ServicePartnersListProps, "onItemDelete"> {
  deleteService: (config: { id: string }) => Promise<unknown>;
  createAllowedTypes: ("myPup" | "iqbi" | "general")[];
}

export function Layout(props: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const canCreatePages = useResolvedPermission(canCreateNew);
  const { componentProps: deleteModalProps, openDeleteModal } =
    useDeleteModal<DeleteRequestParams>("service-delete-modal");
  const navigate = useNavigate();
  const { setting: useNewCardLayout } = useConfig("useNewCardLayout");

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.service.list.title")}
      subTitle={t("page.service.list.sub-title")}
      actions={
        canCreatePages ? (
          props.createAllowedTypes.length > 1 ? (
            <ContextMenu
              actions={props.createAllowedTypes
                .map((type) =>
                  type === "general"
                    ? {
                        text: t("model.service.action.new.general"),
                        callback: () => navigate(routes.servicePartners.create({ slug })),
                      }
                    : type === "iqbi"
                      ? {
                          text: t("model.service.action.new.iqbi"),
                          callback: () =>
                            navigate({ pathname: routes.servicePartners.create({ slug }), search: "integration=iqbi" }),
                        }
                      : type === "myPup"
                        ? {
                            text: t("model.service.action.new.mypup"),
                            callback: () => {
                              navigate({
                                pathname: routes.servicePartners.create({ slug }),
                                search: "integration=mypup",
                              });
                            },
                          }
                        : undefined,
                )
                .filter(isDefined)}
            >
              {(props) => (
                <Button
                  styling="primary"
                  isPressed={props.isOpen}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.openHandlers.toggle();
                  }}
                  data-testid="service-create-page-btn"
                >
                  <span className="flex items-center gap-1">{t("model.service.action.new")}</span>
                </Button>
              )}
            </ContextMenu>
          ) : (
            <Button
              type="link"
              className="w-full"
              href={routes.servicePartners.create({ slug })}
              data-testid="service-create-page-link"
            >
              {t("model.service.action.new")}
            </Button>
          )
        ) : null
      }
    >
      {useNewCardLayout ? (
        <CardGrid>
          {props.data.map((service) => (
            <ServiceCard
              key={service.id}
              id={service.id}
              title={service.title}
              subtitle={service.subtitle}
              email={service.email}
              phone={service.phoneNumber}
              type={service.type}
              connectionName={service.connection?.name}
              image={service.headerImage}
              canEdit={service.canEdit}
              canDelete={service.canDelete}
              onDelete={openDeleteModal}
              dataTestId="service-card"
            />
          ))}
        </CardGrid>
      ) : (
        <ServicePartnersList
          data={props.data}
          hasMoreData={props.hasMoreData}
          isMoreDataLoading={props.isMoreDataLoading}
          isDataLoading={props.isDataLoading}
          loadMoreData={props.loadMoreData}
          onItemDelete={openDeleteModal}
        />
      )}
      <DeleteModal<DeleteRequestParams>
        title={t("model.service.action.delete.confirmation")}
        description={t("model.service.action.delete.description")}
        onDelete={props.deleteService}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}

interface DeleteRequestParams {
  id: string;
}
