import { Button } from "components/Button/Button";
import { NotificationIcon } from "components/NotificationIcon/NotificationIcon";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { X as XIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface NotificationPayload {
  content: string;
  type: "unknown" | "message" | "messageComment" | "groupMessage" | "ticket" | "ticketAssignee" | "ticketComment";
  link?: string;
  "data-testid"?: string;
  autoClose?: number;
}

export interface NotificationProps extends NotificationPayload {
  onDismiss: () => void;
}

export function Notification(props: NotificationProps): React.ReactNode {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimer(setTimeout(props.onDismiss, props.autoClose || 5000));

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [props.autoClose, props.onDismiss, timer]);

  const onClickOpenNotification = () => {
    props.onDismiss();

    if (props.link) navigate(props.link);
  };

  return (
    <motion.div
      className="relative mx-auto flex min-h-16 max-w-[90%] flex-col gap-3 rounded-b-lg bg-white p-3 shadow-md sm:max-w-[80%] sm:flex-row sm:items-center md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%]"
      key={`notification-${props.content}-${props.link}`}
      initial={{ y: "-100%", scale: 0.3 }}
      animate={{ y: "0%", scale: 1 }}
      exit={{ y: "-100%", scale: 0.6 }}
      data-testid={props["data-testid"]}
    >
      <div className="flex">
        <span className="shrink-0 px-2 py-1 align-middle text-aop-dark-blue">
          <NotificationIcon type={props.type} />
        </span>
        <p
          className="flex-1 py-1 text-sm font-semibold text-grey-dark [&_b]:font-bold [&_b]:text-black"
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
      </div>
      <div className="flex w-full justify-between gap-2 sm:ml-auto sm:w-auto">
        {props.link && <Button onClick={onClickOpenNotification}>{t("component.notification.open")}</Button>}
        <button
          className="ml-auto px-2 text-grey-dark transition-transform duration-300 hover:rotate-90 hocus:text-grey-darkest"
          onClick={props.onDismiss}
        >
          <XIcon size={24} />
        </button>
      </div>
    </motion.div>
  );
}
