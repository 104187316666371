import type { ChatDto } from "api/types";
import users01Icon from "assets/icons/users-01.svg";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Capture2, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { cutOffAfterFirstLine, limitTextByChar } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { Paperclip } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { SystemChatMessage } from "./SystemChatMessage";

const CHAR_LIMIT = 120;

interface ChatMessageProps {
  chatDetails: ChatDto;
  index: number;
  isLast: boolean;
}

export function Chat({ chatDetails, index, isLast }: ChatMessageProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  const { chatType, privateChatInfo, groupChatInfo, latestReply, unreadReplies, hasUnreadReply } = chatDetails;

  return (
    <Link
      className={twJoin(
        "flex cursor-pointer items-center justify-between border-b border-b-grey-lightest p-4 hover:drop-shadow-sm focus:outline-none focus-visible:cursor-pointer focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-aop-basic-blue active:bg-grey-lightest",
        index % 2 === 0 ? "bg-white" : "bg-aop-light-grey",
        index === 0 && "rounded-t-md",
        isLast && "rounded-b-md",
      )}
      to={
        chatType === "single"
          ? routes.users.details({ slug, id: privateChatInfo!.receiver.id })
          : routes.chats.details({ slug, id: chatDetails.id })
      }
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="size-10 shrink-0">
            {chatType === "single" || groupChatInfo?.image ? (
              <UserAvatar
                img={chatType === "single" ? privateChatInfo!.receiver.avatar : groupChatInfo!.image}
                isUserDeleted={chatType === "single" ? !!privateChatInfo!.receiver.deletedAt : false}
                hideDeletedIcon
              />
            ) : (
              <div className="flex size-10 items-center justify-center rounded-full border border-grey-lightest p-1 text-grey-light">
                <Icon name={users01Icon} size={24} />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-0">
            <div className="flex items-center gap-1">
              <span
                className={twJoin(
                  "font-semibold",
                  chatType === "single" && privateChatInfo!.receiver.deletedAt ? "text-grey" : "text-black",
                )}
              >
                {chatType === "single" ? privateChatInfo!.receiver.fullName : groupChatInfo!.name}
              </span>
              {chatType === "single" && !!privateChatInfo!.receiver.deletedAt && <UserDeletedTag />}
            </div>
            <div>
              {latestReply?.replyType?.includes("system") ? (
                <SystemChatMessage
                  chatName={groupChatInfo?.name}
                  chatMessage={latestReply}
                  className="text-grey-darker"
                />
              ) : latestReply?.text ? (
                <Capture2 className="sentry-block text-grey-darker">
                  {chatType === "group" ? (
                    <span className="font-semibold">{`${latestReply.author?.id === sessionUser.id ? t("page.chats.list.self-message") : latestReply.author?.fullName}: `}</span>
                  ) : null}
                  {cutOffAfterFirstLine(limitTextByChar(latestReply.text, CHAR_LIMIT))}
                </Capture2>
              ) : (
                latestReply?.image && (
                  <span className="flex items-center gap-1 text-capture2 text-grey-darker">
                    {chatType === "group" ? (
                      <span className="font-semibold">{`${latestReply.author?.id === sessionUser.id ? t("page.chats.list.self-message") : latestReply.author?.fullName}: `}</span>
                    ) : null}
                    <Paperclip className="text-grey-light" size={16} />
                    <Capture2>{t("page.chats.attachment")}</Capture2>
                  </span>
                )
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-start gap-2">
          {latestReply?.sentAt && (
            <p className={twJoin("text-sm", hasUnreadReply ? "text-aop-basic-blue" : "text-grey-darker")}>
              <FormattedDate format="datetime" date={latestReply?.sentAt} />
            </p>
          )}
          {unreadReplies > 0 && (
            <div className="flex size-5 items-center justify-center rounded-full bg-aop-basic-blue p-1 text-white">
              <Overline2>{unreadReplies}</Overline2>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}
