import { useInfiniteQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { QuestionGroupAnswerDto } from "api/types";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import { Table } from "components/Table/Table";
import { Capture2, Headline4 } from "components/Text/Text";
import { useProjectId } from "hooks/Network/useProjectId";
import { useOnIntersection } from "hooks/useOnIntersection";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo } from "react";
import { Star as StarIcon } from "react-feather";
import { useTranslation } from "react-i18next";

export interface QuestionData {
  questionNum: number;
  ratingQuestion: string;
  openQuestion: string;
}

export interface QuestionDetailsProps {
  queueId?: string;
  questionGroupId?: string;
  questionData: QuestionData | null;
}

const QUESTION_RESPONSE_LIMIT = 50;

export function QuestionDetails({ queueId, questionGroupId, questionData }: QuestionDetailsProps): React.ReactNode {
  const projectId = useProjectId();
  const { i18n, t } = useTranslation();
  const api = useApi();

  const {
    data: questionDetailsData,
    isPending: isLoadingAnswers,
    hasNextPage: hasMoreAnswers,
    isFetchingNextPage: isLoadingMoreAnswers,
    fetchNextPage: fetchMoreAnswers,
    error,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_QUESTION_DETAILS(projectId, queueId!, questionGroupId!),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getAutomatedSurveysQueuesQuestionsDetailsV1(queueId!, questionGroupId!, {
        Offset: pageParam * QUESTION_RESPONSE_LIMIT,
        Limit: QUESTION_RESPONSE_LIMIT,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.questionGroupAnswers.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const questionDetails = useMemo(
    () => questionDetailsData?.pages.flatMap((x) => x.questionGroupAnswers.items ?? []) ?? [],
    [questionDetailsData],
  );

  const totalResponses = useMemo(() => questionDetailsData?.pages[0].questionGroupAnswers.total, [questionDetailsData]);

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreAnswers && hasMoreAnswers) {
        void fetchMoreAnswers();
      }
    }, [fetchMoreAnswers, hasMoreAnswers, isLoadingMoreAnswers]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<QuestionGroupAnswerDto>();

    return [
      helper.accessor("rating", {
        header: t("component.surveys-question.details.table.header.rating"),
        cell: (cell) => (
          <span className="flex items-center gap-1 text-center">
            <span>{cell.getValue()}</span>
            <StarIcon className="shrink-0 text-yellow" size={16} />
          </span>
        ),
        maxSize: 40,
      }),
      helper.accessor("openAnswerText", {
        header: t("component.surveys-question.details.table.header.open"),
        cell: (cell) => <span className="block py-0.5">{cell.getValue()}</span>,
      }),
      helper.accessor("userName", {
        header: t("component.surveys-question.details.table.header.username"),
        cell: (cell) => <span className="block truncate">{cell.getValue()}</span>,
        maxSize: 100,
      }),
      helper.accessor("date", {
        header: t("component.surveys-question.details.table.header.date"),
        cell: (cell) => <span>{formatDate(i18n, "datetimeShort", cell.getValue()!)}</span>,
        maxSize: 50,
      }),
    ];
  }, [i18n, t]);

  const tableInstance = useReactTable<QuestionGroupAnswerDto>({
    columns,
    data: questionDetails,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!questionData || error) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 px-6 py-4">
      <Headline4 as="h2">
        {t("component.surveys-question.details.question-number", { number: questionData.questionNum })}
      </Headline4>
      <div className="flex flex-col">
        <Headline4>{questionData.ratingQuestion}</Headline4>
        <span className="text-grey-dark">({questionData.openQuestion})</span>
      </div>
      <Capture2>{t("component.surveys-question.details.responses", { count: totalResponses })}</Capture2>
      {isLoadingAnswers ? (
        <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
      ) : (
        <>
          <Table
            table={tableInstance}
            data-testid="survey-answer-list"
            getRowProps={() => ({
              "data-testid": "survey-answer-item",
            })}
          />
          {hasMoreAnswers && (
            <div className="p-4" ref={loaderRef}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
