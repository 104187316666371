import { useAccount } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "components/Error/ErrorPage";
import { useSessionUserInitial } from "hooks/Network/useSessionUserInitial";
import { useSetAtom } from "jotai";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { matchPath, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { languageAtom } from "state/app-language";

import { useProjectContext } from "./ProjectContext";

/**
 * Makes sure a session user is available in all child components.
 * If isRoot is true, it will also redirect to the onboarding flow if the user is not onboarded.
 */
export function SessionUserLoader({
  children,
  isRoot,
}: {
  children: React.ReactNode;
  isRoot?: boolean;
}): React.ReactNode {
  const account = useAccount();
  const navigate = useNavigate();
  const projectContext = useProjectContext();
  const { data: user, error } = useSessionUserInitial(projectContext.projectId);
  const setLanguage = useSetAtom(languageAtom);
  const posthog = usePostHog();

  useEffect(() => {
    if (!isRoot) {
      return;
    }

    if (user) {
      const role = user.isSuperAdmin ? "Super admin" : user.isAdmin ? "Professional user" : "Resident";

      Sentry.getCurrentScope().setUser({
        email: user.email,
        username: account?.localAccountId,
        segment: role,
      });

      posthog?.identify(user.id, {
        projectId: user.projectId,
        email: user.email,
        isAdmin: user.isAdmin,
      });
    }
  }, [isRoot, account?.localAccountId, user, posthog]);

  const notOnboarded = isRoot && user && !user.onboardedAt && !matchPath(routes.onboardingFlow.ROOT, location.pathname);

  // Redirect to onboarding
  useEffect(() => {
    if (notOnboarded) {
      navigate(routes.onboardingFlow.slides({ slug: projectContext.projectSlug }), { replace: true });
    }
  }, [navigate, notOnboarded, projectContext.projectSlug]);

  useEffect(() => {
    if (!isRoot) {
      return;
    }

    if (user) {
      setLanguage(user.language.id);
    }
  }, [isRoot, user, user?.language.id, setLanguage]);

  if (error) {
    return <ErrorPage status={(error as unknown as Response)?.status || 0} />;
  }

  if (!user || notOnboarded) {
    return null;
  }

  return children;
}
