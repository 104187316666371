import { useImageResolver } from "api/hooks/useImageResolver";
import type { CommunityGroupV2Dto, ImageDto } from "api/types";
import award from "assets/images/award.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { StaticButton } from "components/Button/StaticButton";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { Label } from "components/Label/Label";
import { TagList } from "components/Tag/TagList";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import { Users as UsersIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export interface GroupProps {
  group: CommunityGroupV2Dto;
  follow: GroupAction;
  unfollow: GroupAction;
  contextActions: ContextMenuAction[];
  allAudienceLabel: string;
  fallbackHeaderImage?: ImageDto;
  disabled?: boolean;
}
interface GroupAction {
  label: React.ReactNode;
  handler: (id: string) => void;
}

export function Item({
  group,
  follow,
  unfollow,
  contextActions,
  allAudienceLabel,
  fallbackHeaderImage,
  disabled,
}: GroupProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const resolveImage = useImageResolver();

  const audience = group.audience?.map(({ key, ...item }) => ({ content: key, ...item }));
  const detailsRoute = group.type === "helpCategory" ? routes.helpCategories.details : routes.interestGroups.details;
  const userImage = group.image ? group.image : fallbackHeaderImage;

  return (
    <li
      className="relative my-4 flex flex-wrap gap-4 rounded-3px border border-grey-lighter p-2 first-of-type:mt-0 last-of-type:mb-0 sm:my-2.5 sm:flex-nowrap sm:p-4"
      data-testid="group-list-item"
    >
      <div
        className={twJoin(
          "relative mx-auto flex h-44 w-full shrink-0 items-center justify-center overflow-hidden rounded-3px bg-center before:left-0 before:top-0 before:size-full before:bg-black/55 before:content-[''] sm:h-auto sm:w-64",
          userImage ? "bg-cover bg-no-repeat" : "bg-contain bg-repeat",
        )}
        style={{
          backgroundImage: `url(${userImage ? resolveImage(userImage, "large") : bgPlaceholder})`,
        }}
        data-testid="group-list-item-image"
      >
        {!group.isResidentGroup ? (
          <div
            className={twJoin(
              "absolute left-1/2 top-1/2 flex size-20 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-3px bg-white sm:size-14",
              disabled && "opacity-50",
            )}
          >
            <GroupIcon
              size={32}
              className="flex w-20 items-center justify-center stroke-none text-[2.5rem] sm:w-6 sm:text-[1.5rem]"
              icon={group.icon}
            />
          </div>
        ) : null}
      </div>
      <div className="flex w-full flex-1 flex-col justify-between">
        <div className="mb-2 flex flex-col sm:mb-6">
          <div className="flex items-center justify-between">
            <Anchor style="inherit" to={detailsRoute({ slug, id: group.id })}>
              <Subtitle2
                className={twJoin("mb-1 max-h-[3rem] overflow-hidden", disabled && "opacity-50")}
                data-testid="group-list-item-name"
              >
                {group.name}
              </Subtitle2>
            </Anchor>
            <div className="flex items-center gap-2">
              {sessionUser.isAdmin &&
              (group.type === "realEstate" || group.projectConnectionGroupId || group.systemGroupId) ? (
                <Label theme="gray">
                  {group.type === "realEstate"
                    ? t("page.discovery.group-type.real-estate")
                    : group.projectConnectionGroupId
                      ? t("page.discovery.group-type.project-connection")
                      : group.systemGroupId
                        ? t("page.discovery.group-type.platform")
                        : null}
                </Label>
              ) : null}
              <ContextMenu actions={contextActions} />
            </div>
          </div>
          <Capture2 className={twJoin("mb-3.5 line-clamp-2 text-grey-darker", disabled && "opacity-50")}>
            {group.description}
          </Capture2>
          {sessionUser.isAdmin ? (
            <div
              className={twJoin(
                "hidden sm:flex sm:items-center sm:gap-1 sm:text-grey-darker",
                disabled && "opacity-50",
              )}
            >
              <UsersIcon size={12} />
              {audience?.length ? <TagList tags={audience} limit={3} /> : <i>{allAudienceLabel}</i>}
            </div>
          ) : null}
        </div>
        <div className="flex flex-wrap items-center justify-between gap-2">
          {!disabled &&
            (group.isOwner && group.isResidentGroup ? (
              <StaticButton>
                <div className="flex items-center gap-1">
                  <img className="size-4" src={award} alt="" />
                  <span>{t("model.interest-group.owner")}</span>
                </div>
              </StaticButton>
            ) : (
              <Button
                className="w-full sm:w-auto sm:max-w-full"
                data-testid="group-action-follow-unfollow"
                styling={group.isMember ? "secondary" : "secondary"}
                onClick={() => {
                  if (group.isMember) {
                    unfollow.handler(group.id);
                  } else {
                    follow.handler(group.id);
                  }
                }}
              >
                {group.isMember ? unfollow.label : follow.label}
              </Button>
            ))}
        </div>
      </div>
    </li>
  );
}
