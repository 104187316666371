import { Icon } from "components/Icon/Icon";
import type React from "react";

interface NoticeProps {
  icon: string;
  message: string;
}

export function Notice({ icon, message }: NoticeProps): React.ReactNode {
  return (
    <div className="flex w-full items-center gap-4 rounded-lg border border-blue bg-blue-lightest p-4">
      <Icon name={icon} size={24} className="text-blue" />
      <p className="text-capture1 text-blue">{message}</p>
    </div>
  );
}
